<template>
  <div>
    <UpdateTab 
      v-for="(mappedUpdate, index) in mappedUpdates" 
      :key="index" 
      :event="mappedUpdate" 
      :urls="reactionUrls" 
      :reactionCounts="reactionCounts" 
      :timelineIndex="index" 
      :conflictName="conflictName" 
      :eventsLength="props.selectedConflictUpdates.length"
      allowReactions 
      computeTime 
      @react="handleReaction"
    />
  </div>
</template>

<script setup>
import UpdateTab from "./UpdateTab.vue"; 
import { computed, ref } from "vue";

const props = defineProps({
  selectedConflictUpdates: {
    type: Array,
    required: true,
  },
  conflictName: {
    type: String,
    required: true,
  },
});

const reactionCounts = ref({});
const reactionUrls = [
  { type: 'heart', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f49c/lottie.json' },
  { type: 'happy', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f600/lottie.json' },
  { type: 'pray', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f64f/lottie.json' },
  { type: 'surprised', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f631/lottie.json' },
  { type: 'cry', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/lottie.json' },
  { type: 'angry', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f621/lottie.json' },
  { type: 'cap', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f393/lottie.json' }
];

async function handleReaction({ eventId, reactionType }) {
  if (!reactionCounts.value[eventId]) {
    reactionCounts.value[eventId] = {};
  }
  if (!reactionCounts.value[eventId][reactionType]) {
    reactionCounts.value[eventId][reactionType] = 0;
  }
  reactionCounts.value[eventId][reactionType]++;
}

const mappedUpdates = computed(() => {
  let updateList = [...props.selectedConflictUpdates];  // Clone to avoid mutating props
  let reversedUpdates = updateList.reverse();
  return reversedUpdates.map((update) => ({
    id: update.id,
    EventHeadline: update.Update,
    EventDate: update.DateTime,
    EventDescription: update.UpdateDesc,
    SourceOfUpdate: update.SourceOfUpdate,
  }));
});
</script>
