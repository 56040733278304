// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCXX5QN-JET0r2hkMlgdQESqWKZZM7Dpco",
    authDomain: "tldrmap.firebaseapp.com",
    projectId: "tldrmap",
    storageBucket: "tldrmap.appspot.com",
    messagingSenderId: "600061727958",
    appId: "1:600061727958:web:48110eeb2576760278e577"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore and Auth
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
