// Various Computation/Method Imports
import { timeSince } from "@/services/computation/calcTimeSince.js";
import { computeCentroid } from "@/services/computation/computeCentroidCalc";
import L from "leaflet";
import { toRaw } from "vue";
import '@/assets/mapanimations.css';
import emitter from '@/stores/markerEmitter';

function getSourceName(url) {
    if (url.includes('twitter.com')) return 'Twitter';
    if (url.includes('t.me')) return 'Telegram';
    if (url.includes('aljazeera.com')) return 'Al Jazeera';
    return 'Website';
}


// Method to Access Conflict Updates
export function getConflictUpdates(conflict, context, layer) {
    // Markers and Updates - Set the Time and Date; Change first number in updateDateRange to Change Number of Days
    const currentDate = new Date();
    const updateDateRange = 5 * 24 * 60 * 60 * 1000; // 

    context.markers.forEach(marker => {
        const conflict = marker.options.properties;
        if (conflict.ConflictName === "Global") {
            marker._icon.style.display = "";
            if (marker._shadow) {
                marker._shadow.style.display = "";
            }
        } else {
            marker._icon.style.display = "none";
            if (marker._shadow) {
                marker._shadow.style.display = "none";
            }
        }
    });

    // Go Through Each Conflicts Updates
    if (conflict.Updates && conflict.Updates.length > 0) {

        // Filter Out Updates
        const recentUpdates = conflict.Updates.filter(update => {
            const updateDate = new Date(update.DateTime);
            return currentDate.getTime() - updateDate.getTime() <= updateDateRange;
        });

        // Sort updates by DateTime to get the most recent ones
        recentUpdates.sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime));

        // Go Through Each Update
        recentUpdates.forEach((update) => {
            // Access Coordinates
            let coordinates = layer.feature.geometry.coordinates[0];
            let avgLat, avgLon;

            // Check if "Coordinates" property exists in the current update and if its first index is not ""
            if (update.Coordinates && update.Coordinates["Lat"] !== "") {
                [avgLat, avgLon] = [update.Coordinates["Lat"], update.Coordinates["Lng"]];
            } else {
                [avgLat, avgLon] = computeCentroid(coordinates);
            }

            // Create Map Icon, adjust shadow and anchor for popup and display
            var mapIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',

                iconSize: [25, 41],
                iconAnchor: [12, 41],
                shadowSize: [41, 41],
                popupAnchor: [-3, -76]
            });

            // Set Up Marker Properties For Filter Use
            L.MarkerWithProps = L.Marker.extend({
                options: {
                    properties: {}
                }
            });

            // Create Marker with Icon and Properties at Lat,Lng
            const marker = new L.MarkerWithProps([avgLat, avgLon], {
                icon: mapIcon,
                properties: conflict
            }).addTo(toRaw(context.map));

            const elapsedTime = timeSince(new Date(update.DateTime))[0];
            const elapsedTimeCalc = timeSince(new Date(update.DateTime))[1];
            // Apply flashing effect to the two most recent updates
            if (elapsedTimeCalc < getConflictMapMarkerTime(conflict.TypeOfConflict)) {
                const element = marker._icon || marker._path;
                if (element) {
                    element.classList.add('flash');
                }
            }

            // Add the Click Popup
            marker.on("click", () => {
                context.conflictSelected = true;
                context.showConflictsTab = true;
                emitter.emit('openUpdateFeedTab');

                // Update Conflicts Tab
                context.selectedConflictSidesInvolved = conflict.sidesArray;
                context.selectedConflict = conflict.ConflictName;
                context.selectedConflictHistory =
                    conflict.HistoryOfConflict;
                context.BackgroundTimeline = conflict.ConflictTimeline;
                context.selectedConflictUpdates =
                    conflict.Updates;
                context.selectedConflictRegion = conflict.RegionOfConflict;
                if (context.selectedConflictUpdates[0]) {
                    context.selectedConflictLatestUpdate =
                        conflict.Updates[0].update;
                }
                else {
                    context.selectedConflictLatestUpdate = "";
                }
            });

            
            const tooltipContent = `
                <div class="p-4 bg-gray-800 text-white rounded-lg shadow-xl border border-gray-600">
                    <div class="text-lg font-bold border-b border-gray-500 pb-2">${update.Update}</div>
                    <div class="flex-grow text-sm py-2 text-gray-300">${update.UpdateDesc}</div>
                    <div class="mt-2 text-sm flex justify-between items-center">
                        <div>
                            <a href="${update.SourceOfUpdate}" target="_blank" class="text-indigo-200 hover:text-indigo-400 underline">
                                Source: 
                                <span class="font-semibold">
                                    ${getSourceName(update.SourceOfUpdate)}
                                </span>
                            </a>
                        </div>
                        <div class="text-gray-400 text-sm">
                            ${elapsedTime}
                        </div>
                    </div>
                </div>
            `;
            
            // Marker Setup
            // marker.bindTooltip(tooltipContent, {
            //     direction: 'top',
            //     className: 'custom-tooltip',
            //     permanent: false,
            //     opacity: 1
            // })


            // Bind the popup once
            marker.bindPopup(tooltipContent);

            marker.on('click', function () {
                marker.openPopup();
            });

            marker.addTo(toRaw(context.map));
        });
    }
}

// Method to Access Conflict Updates
export function getConflictUpdatesSetup(conflict, context, parsedData) {
    // Markers and Updates - Set the Time and Date; Change first number in updateDateRange to Change Number of Days
    const currentDate = new Date();
    const updateDateRange = 12 * 60 * 60 * 1000; // 

    // Go Through Each Conflicts Updates
    if (conflict.Updates && conflict.Updates.length > 0) {

        // Filter Out Updates
        const recentUpdates = conflict.Updates.filter(update => {
            const updateDate = new Date(update.DateTime);
            return currentDate.getTime() - updateDate.getTime() <= updateDateRange;
        });

        // Sort updates by DateTime to get the most recent ones
        recentUpdates.sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime));

        // Go Through Each Update
        recentUpdates.forEach((update) => {
            // Access Coordinates
            let coordinates = parsedData.features[0].geometry.coordinates[0];
            let avgLat, avgLon;

            // Check if "Coordinates" property exists in the current update and if its first index is not ""
            if (update.Coordinates && update.Coordinates["Lat"] !== "") {
                [avgLat, avgLon] = [update.Coordinates["Lat"], update.Coordinates["Lng"]];
            } else {
                [avgLat, avgLon] = computeCentroid(coordinates);
            }

            // Create Map Icon, adjust shadow and anchor for popup and display
            var mapIcon = L.icon({
                iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-orange.png',
                shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',

                iconSize: [25, 41],
                iconAnchor: [12, 41],
                shadowSize: [41, 41],
                popupAnchor: [-3, -76]
            });

            // Set Up Marker Properties For Filter Use
            L.MarkerWithProps = L.Marker.extend({
                options: {
                    properties: {
                    }
                }
            });

            // Create Marker with Icon and Properties at Lat,Lng
            const marker = new L.MarkerWithProps([avgLat, avgLon], {
                icon: mapIcon,
                properties: conflict,
                customOptions: {
                    flash: false
                }
            }).addTo(toRaw(context.map));

            const elapsedTime = timeSince(new Date(update.DateTime))[0];
            const elapsedTimeCalc = timeSince(new Date(update.DateTime))[1];

            // Function to apply flashing effect to a marker
            function applyFlashEffect(marker) {
                const element = marker._icon || marker._path;
                if (element) {
                    element.classList.add('flash');
                    setTimeout(() => {
                        element.classList.remove('flash');
                    }, 10000); // Remove the flash class after 5 seconds
                }
            }

            // Apply flashing effect to the two most recent updates
            if (elapsedTimeCalc < getConflictMapMarkerTime(conflict.TypeOfConflict)) {
                applyFlashEffect(marker);
                marker.options.customOptions.flash = true;
            }


            // Add the Click Popup
            marker.on("click", () => {
                context.conflictSelected = true;
                context.showConflictsTab = true;
                context.isNewsfeedTabVisible = false;

                // Update Conflicts Tab
                context.selectedConflictSidesInvolved = conflict.sidesArray;
                context.selectedConflict = conflict.ConflictName;
                context.selectedConflictHistory =
                    conflict.HistoryOfConflict;
                context.BackgroundTimeline = conflict.ConflictTimeline;
                context.selectedConflictUpdates =
                    conflict.Updates;
                context.selectedConflictRegion = conflict.RegionOfConflict;
                if (context.selectedConflictUpdates[0]) {
                    context.selectedConflictLatestUpdate =
                        conflict.Updates[0].update;
                }
                else {
                    context.selectedConflictLatestUpdate = "";
                }
            });



            const tooltipContent = `
                <div class="p-4 bg-gray-800 text-white rounded-lg shadow-xl border border-gray-600">
                    <div class="text-lg font-bold border-b border-gray-500 pb-2">${update.Update}</div>
                    <div class="flex-grow text-sm py-2 text-gray-300">${update.UpdateDesc}</div>
                    <div class="mt-2 text-sm flex justify-between items-center">
                        <div>
                            <a href="${update.SourceOfUpdate}" target="_blank" class="text-indigo-200 hover:text-indigo-400 underline">
                                Source: 
                                <span class="font-semibold">
                                    ${getSourceName(update.SourceOfUpdate)}
                                </span>
                            </a>
                        </div>
                        <div class="text-gray-400 text-sm">
                            ${elapsedTime}
                        </div>
                    </div>
                </div>
            `;

            // Marker Setup
            marker.bindPopup(tooltipContent);

            marker.on('click', function () {
                marker.openPopup();
            });


            marker.addTo(toRaw(context.map));
        });
    }
}


// Method to Access Global Updates
export function getGlobalUpdates(docClient, context) {
    // Access Global Updates Table
    const global_updates_table_name = "TLDRMapGlobalNews";

    function applyFlashEffect(marker) {
        const element = marker._icon || marker._path;
        if (element) {
            element.classList.add('flash');
            setTimeout(() => {
                element.classList.remove('flash');
            }, 10000); // Remove the flash class after 5 seconds
        }
    }

    const newParams = {
        TableName: global_updates_table_name,
    };

    // Scan Table 
    docClient.scan(newParams, (err, data) => {
        if (err) {
            console.error("Error fetching data:", err);
        } else {
            const updates = data.Items;

            // Date Range for Global Updates; Change first number of updateDateRange to adjust day 
            const currentDate = new Date();
            const updateDateRange = 300 * 24 * 60 * 60 * 1000;

            // Go Through Each Global Update
            updates.forEach(async (update) => {
                const updateDate = new Date(update.DateTime);
                const timeDifference = currentDate - updateDate;

                // Filter Out Updates on Range
                if (timeDifference <= updateDateRange) {
                    let [avgLat, avgLon] = [update.Coordinates["Lat"], update.Coordinates["Lng"]];

                    // Create Map Icon
                    var mapIcon = L.icon({
                        //iconUrl: 'Mapmarkers/mapmarker_' + update.TypeOfUpdate + '.png',
                        iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
                        shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',

                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        shadowSize: [41, 41],
                        popupAnchor: [-3, -76]
                    });

                    //Marker and Properties
                    L.MarkerWithProps = L.Marker.extend({
                        options: {
                            properties: {
                                ConflictName: "Global",
                            }
                        }
                    });

                    const marker = new L.MarkerWithProps([avgLat, avgLon], {
                        icon: mapIcon,
                    }).addTo(toRaw(context.map));


                    const elapsedTime = timeSince(new Date(update.DateTime))[0];
                    const elapsedTimeCalc = timeSince(new Date(update.DateTime))[1];
                    
                    if (elapsedTimeCalc < 86400) {
                        applyFlashEffect(marker);
                        marker.options.customOptions.flash = true;
                    }
                    // Global Updates Tooltip
                    const tooltipContent = `
                    <div class="p-4 bg-gray-800 text-white rounded-lg shadow-xl border border-gray-600">
                        <div class="text-lg font-bold border-b border-gray-500 pb-2">${update.Update}</div>
                        <div class="flex-grow text-sm py-2 text-gray-300">${update.UpdateDesc}</div>
                        <div class="mt-2 text-sm flex justify-between items-center">
                            <div>
                                <a href="${update.SourceOfUpdate}" target="_blank" class="text-indigo-200 hover:text-indigo-400 underline">
                                    Source: 
                                    <span class="font-semibold">
                                        ${getSourceName(update.SourceOfUpdate)}
                                    </span>
                                </a>
                            </div>
                            <div class="text-gray-400 text-sm">
                                ${elapsedTime}
                            </div>
                        </div>
                    </div>
                `;

                    // Bind the popup once
                    marker.bindTooltip(tooltipContent, {
                        direction: 'top',
                        className: 'custom-tooltip',
                        permanent: false,
                        opacity: 1
                    })

                    marker.addTo(toRaw(context.map));


                }
            });
        }
    });
}


function getConflictMapMarkerTime(type) {
    const type_time_dict = {
        "Invasion": 43200, // 12 hours 
        "Civil War": 86400, // 1 day
        "Frozen Conflict": 172800, // 2 days
        "Ethnic Conflict": 172800, // 2 days
        "Insurgency": 604800, // 1 week
        "Territorial Dispute": 172800, // 2 days
        "default": 172800 // 2 days
    }

    return type_time_dict[type];
}