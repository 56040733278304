export function computeCentroid(coordinates) {
  let area = 0;
  let centroidX = 0;
  let centroidY = 0;

  for (let i = 0; i < coordinates.length - 1; i++) {
    let xi = coordinates[i][0];
    let yi = coordinates[i][1];
    let xi1 = coordinates[i + 1][0];
    let yi1 = coordinates[i + 1][1];

    let tempArea = xi * yi1 - xi1 * yi;
    area += tempArea;
    centroidX += (xi + xi1) * tempArea;
    centroidY += (yi + yi1) * tempArea;
  }

  area /= 2;
  centroidX /= (6 * area);
  centroidY /= (6 * area);

  return [centroidY, centroidX]; // return as [lat, lng]
}