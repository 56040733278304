<!-- eslint-disable no-unused-vars -->

<template>
  <div class="icon-filter-container w-full p-4 rounded-lg space-y-4 border border-gray-700 dark:border-gray-600 bg-gray-800 dark:bg-gray-800">
    <div class="upper-icons flex justify-center space-x-6 items-center rounded-md transition-colors duration-300">
      <!-- Marker Filter -->
      <div @click="applyMarkerFilter()">
        <MapPinIcon class="h-6 w-6" v-if="markerVisibility"/>
        <MapPinSolid class="h-6 w-6" v-if="!markerVisibility"/>
      </div>

      <!-- Drawing Filter -->
      <div @click="applyDrawerFilter">
        <PencilIcon class="h-6 w-6" v-if="drawerVisibility === 'off'"/>
        <PencilIconSolid class="h-6 w-6" v-if="drawerVisibility === 'on'"/>
      </div>

      <!-- Toggle Layer Icon -->
      <div @click="toggleLayer" title="Turn Conflict Zones On/Off">
        <MapIcon class="h-6 w-6" v-if="layersVisible"/>
        <MapIconSolid class="h-6 w-6" v-if="!layersVisible"/>
      </div>

    </div>
    <!-- Color Selection Menu -->
    <div v-if="drawerVisibility === 'on'" class="p-4 rounded-lg shadow-md">
      <div class="p-4">
        <input type="color" v-model="color" @input="setColor(color)" class="w-full h-10" />
      </div>

      <div class="line-width-slider">
        <label for="lineWidthSlider" class="block text-sm font-medium text-gray-400 mb-1">Line Width:</label>
        <input type="range" id="lineWidthSlider" v-model="lineWidth" min="1" max="50" class="w-full" @input="setLineWidth(lineWidth)" />
      </div>

      <!-- Action Icon Grid -->
      <div class="action-icon-grid grid grid-cols-2 sm:grid-cols-3 gap-4 mt-4">
          <!-- Save Icon -->
          <div class="color-option bg-yellow-500 dark:bg-gray-800 rounded-lg shadow-md cursor-pointer items-center transition-colors duration-300 hover:bg-yellow-600 dark:hover:bg-gray-700" @click="toggleSavePopup" title="Save">
            <ArrowDownTrayIcon class="h-6 w-6 text-white dark:text-gray-300 ml-[1.15rem]" />
            <span class="block text-xs font-semibold mt-1">Save</span>
          </div>

          <!-- Erase All Icon -->
          <div class="color-option bg-yellow-500 dark:bg-gray-800 rounded-lg shadow-md cursor-pointer text-center transition-colors duration-300 hover:bg-yellow-600 dark:hover:bg-gray-700" @click="eraseEverything" title="Erase All">
            <TrashIcon class="h-6 w-6 text-white dark:text-gray-300 ml-[1.15rem]" />
            <span class="block text-xs font-semibold mt-1">Erase All</span>
          </div>

          <!-- Eraser Icon -->
          <div @click="toggleEraserMode" title="Eraser">
            <BackspaceIcon v-if="!isErasing" class="h-6 w-6 text-white dark:text-gray-300 ml-[1.15rem]" />
            <PencilIcon v-if="isErasing" class="h-6 w-6 text-white dark:text-gray-300 ml-[1.15rem]" />
            <span class="block text-xs font-semibold mt-1">{{ isErasing ? 'Pen' : 'Eraser' }}</span>
          </div>
      </div>
    </div>

    <!-- Save Popup -->
    <div v-if="showSavePopup" class="save-popup-overlay bg-black bg-opacity-50 flex justify-center items-center fixed inset-0 z-50">
      <div class="save-popup bg-gray-900 p-4 rounded-lg relative">
        <button @click="toggleSavePopup" class="close-btn absolute top-2 right-2 text-xl text-gray-400 cursor-pointer">
          X
        </button>

        <!-- Save Section -->
        <div class="save-section mt-4">
          <p class="text-gray-400">Save Current Drawing:</p>
          <input
            type="text"
            v-model="drawingName"
            placeholder="Enter drawing name"
            class="w-full px-4 py-2 mt-2 rounded-md bg-gray-800 border border-gray-700 text-gray-400 focus:outline-none focus:border-yellow-500"
          />
          <button @click="saveMapImage(drawingName)" class="px-4 py-2 mt-2 bg-gradient-to-r from-yellow-500 to-orange-500 text-white rounded-md cursor-pointer transition-colors duration-300">
            Save
          </button>
        </div>

        <!-- Load Section -->
        <div class="load-section mt-4">
          <p class="text-gray-400">Load Saved Drawing:</p>
          <select
            v-model="selectedSavedDrawing"
            class="w-full px-4 py-2 mt-2 rounded-md bg-gray-800 border border-gray-700 text-gray-400 focus:outline-none focus:border-yellow-500"
          >
            <option disabled value="">Please select a drawing</option>
            <option v-for="(drawing, index) in savedDrawings" :key="index" :value="drawing">{{ drawing.name }}</option>
          </select>
          <button @click="loadDrawing(selectedSavedDrawing), showSavePopup = false" class="px-4 py-2 mt-2 bg-gradient-to-r from-yellow-500 to-orange-500 text-white rounded-md cursor-pointer transition-colors duration-300">
            Load
          </button>
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { ref, onMounted, watch } from "vue";
// import { getAuth } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { useUserStore } from '@/stores/currentUser';
import { PencilIcon, MapIcon, ArrowDownTrayIcon, TrashIcon, BackspaceIcon, MapPinIcon } from "@heroicons/vue/24/outline";
// eslint-disable-next-line
import { MapPinIcon as MapPinSolid, PencilIcon as PencilIconSolid, MapIcon as MapIconSolid } from '@heroicons/vue/24/solid'

const props = defineProps({
  map: {
    type: Object,
    required: true,
  },
  context: {
    type: Object,
    required: true,
  },
  markers: {
    type: Object,
    required: true,
  },
})

// const setIsDrawing = inject('setIsDrawing')

const localContext = ref(props.context)
const lastDrawingState = ref(null)
const drawingName = ref('')
const db = getFirestore()
const savedDrawings = ref([])
const markerVisibility = ref(true)
const userStore = useUserStore()
const layersVisible = ref(true)
const showSavePopup = ref(false)
const drawerVisibility = ref('off')
let isDrawing = ref(false)
const color = ref('#FF0000')
const lineWidth = ref(5);
const isErasing = ref(false);
// isDrawing = inject('isDrawing')

//watchers
watch(() => props.context, (newContext) => {
  console.log(newContext);
  localContext.value = newContext;
});

watch(() => color, (newColor) => {
  setColor(newColor.value)
})

watch(() => lineWidth.value, (newLineWidth) => {
  setLineWidth(newLineWidth)
  console.log(newLineWidth)
})

//lifecycle hooks

onMounted(async () => {
  await userStore.fetchUser();
  if (userStore.currentUser) {
    const userRef = doc(db, 'users', userStore.uid)
    getDoc(userRef).then((docSnap) => {
      if (docSnap.exists()) {
        savedDrawings.value = docSnap.data().saveMapImages || [];
        console.log(savedDrawings.value)
      }
    })
  }
  const canvas = document.getElementById('drawingCanvas');
  if (canvas) {
    localContext.value = canvas.getContext('2d');
    setLineWidth(lineWidth.value);
    setColor(color.value);
  }
})

//methods

const startDrawing = (e) => {
  if (!localContext.value) return;
  isDrawing.value = true;
  localContext.value.beginPath();
  localContext.value.moveTo(e.offsetX, e.offsetY);
  const canvas = e.target;
  canvas.addEventListener('mousemove', draw);
};

const draw = (e) => {
  if (isDrawing.value) {
    localContext.value.lineTo(e.offsetX, e.offsetY);
    localContext.value.stroke();
  }
};


const stopDrawing = () => {
  if (isDrawing.value) {
    localContext.value.closePath();
    isDrawing.value = false;
    const canvas = document.getElementById('drawingCanvas');
    canvas.removeEventListener('mousemove', draw);
  }
};

const toggleEraserMode = () => {
  isErasing.value = !isErasing.value
  if (isErasing.value) {
    setColor('#FFFFFF')
    localContext.value.globalCompositeOperation = 'destination-out'
  } else setColor(color.value)
}

const enableDrawingMode = () => {
  const canvas = document.getElementById('drawingCanvas');
  if (canvas) {
    canvas.style.display = 'block';
    canvas.addEventListener('mousedown', startDrawing);
    canvas.addEventListener('mouseup', stopDrawing);
    window.addEventListener('keydown', handleUndo);
  } else {
    console.error('Canvas element not found!');
  }
};

const disableDrawingMode = () => {
  const canvas = document.getElementById('drawingCanvas');
  if (canvas) {
    canvas.removeEventListener('mousedown', startDrawing);
    canvas.removeEventListener('mouseup', stopDrawing);
    localContext.value.clearRect(0, 0, canvas.width, canvas.height);
    canvas.style.display = 'none';
    stopDrawing();
    window.removeEventListener('keydown', handleUndo);
  }
};

const eraseEverything = () => {
  const canvas = document.getElementById('drawingCanvas');
  localContext.value.clearRect(0, 0, canvas.width, canvas.height);
};

const saveMapImage = async (drawingName) => {
  await userStore.fetchUser()
  const canvas = document.getElementById('drawingCanvas')
  if (canvas) {
    const dataURL = canvas.toDataURL()
    const userRef = doc(db, 'users', userStore.uid)
    try {
      await updateDoc(userRef, {
        savedMapImages: arrayUnion({ name: drawingName, data: dataURL }),
      });
      console.log('Map image saved successfully')
    } catch (error) {
      console.error('Error saving map image: ', error)
    }
  } else {
    console.error('Canvas element not found')
  }
};

const loadDrawing = (selectedSavedDrawing) => {
  if (selectedSavedDrawing) {
    const canvas = document.getElementById('drawingCanvas')
    const ctx = canvas.getContext('2d')
    const image = new Image()
    image.onload = function () {
      ctx.drawImage(image, 0, 0)
    };
    image.src = selectedSavedDrawing.data;
  }
};

const setColor = (color) => {
  localContext.value.strokeStyle = color;
  // localContext.value.lineWidth = lineWidth.value;
  if (color === '#FFFFFF') {
    localContext.value.globalCompositeOperation = 'destination-out'
    // localContext.value.lineWidth = 15
  } else {
    localContext.value.globalCompositeOperation = 'source-over'
  }
};

const setLineWidth = (newLineWidth) => {
  if (localContext.value) {
    localContext.value.lineWidth = newLineWidth
    console.log(newLineWidth);
  }
}

// const saveLastDrawingState = () => {
//   const canvas = document.getElementById('drawingCanvas')
//   lastDrawingState.value = canvas.toDataURL()
// };

const undoLastStroke = () => {
  if (lastDrawingState.value) {
    const canvas = document.getElementById('drawingCanvas')
    const context = canvas.getContext('2d')
    const img = new Image()
    img.onload = () => {
      context.clearRect(0, 0, canvas.width, canvas.height)
      context.drawImage(img, 0, 0)
    };
    img.src = lastDrawingState.value
    lastDrawingState.value = null
  }
};

const handleUndo = (e) => {
  if (e.key === 'z' && (e.ctrlKey || e.metaKey)) {
    e.preventDefault()
    undoLastStroke()
  }
};

const hideMarkers = () => {
  props.markers.forEach((marker) => {
    marker._icon.style.display = 'none'
    if (marker._shadow) {
      marker._shadow.style.display = 'none'
    }
  });
};

const showMarkers = () => {
  props.markers.forEach((marker) => {
    marker._icon.style.display = ''
    if (marker._shadow) {
      marker._shadow.style.display = ''
    }
  });
};

const applyMarkerFilter = () => {
  if (markerVisibility.value) {
    hideMarkers(props.map)
    markerVisibility.value = !markerVisibility.value
  } else {
    showMarkers(props.map)
    markerVisibility.value = !markerVisibility.value
  }
};

const applyDrawerFilter = () => {
  if (drawerVisibility.value === 'on') {
    drawerVisibility.value = 'off'
    disableDrawingMode()
    document.body.style.cursor = 'default'
  } else {
    drawerVisibility.value = 'on'
    enableDrawingMode()
    document.body.style.cursor = 'cell'
  }
};

const toggleLayer = () => {
  if (layersVisible.value) {
    props.map.eachLayer((layer) => {
      if (layer.feature && layer.feature.properties) {
        layer._path.style.display = 'none'
      }
    });
    props.markers.forEach((marker) => {
      marker._icon.style.display = 'none'
      if (marker._shadow) {
        marker._shadow.style.display = 'none'
      }
    })
    layersVisible.value = false
  } else {
    props.map.eachLayer((layer) => {
      if (layer.feature && layer.feature.properties) {
        layer._path.style.display = ''
      }
    });
    props.markers.forEach((marker) => {
      marker._icon.style.display = ''
      if (marker._shadow) {
        marker._shadow.style.display = ''
      }
    })
    layersVisible.value = true;
  }
}

const toggleSavePopup = () => {
  showSavePopup.value = !showSavePopup.value
}

</script>