import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid'; 

export default function addEditedEvent(eventIndex, conflictName, eventHeadline, eventDate, eventDescription, editedDescription, user) {
    const docClient = new AWS.DynamoDB.DocumentClient();
    const table_name = "TLDRMapPublicTimelineEditsDatabase";

    // Function to highlight the differences between the original and edited descriptions
    function highlightDifferences(original, edited) {
        const originalWords = original.split(' ');
        const editedWords = edited.split(' ');

        let diff = '';
        editedWords.forEach((word, index) => {
            if (word !== originalWords[index]) {
                diff += `<mark>${word}</mark> `;
            } else {
                diff += `${word} `;
            }
        });

        return diff.trim();
    }

    // Generate a unique ID for the new event
    const uniqueId = uuidv4();

    // Highlight the differences between the original and edited descriptions
    const eventEditDescription = highlightDifferences(eventDescription, editedDescription);

    // Create the new event object
    const newEvent = {
        ID: uniqueId, 
        User: user,
        ConflictName: conflictName,
        Index: eventIndex,
        EventHeadline: eventHeadline,
        EventDate: eventDate,
        EventDescription: editedDescription,
        EventEditDescription: eventEditDescription,
    };

    // Define the params for DynamoDB
    const params = {
        TableName: table_name,
        Item: newEvent,
    };

    // Insert the new event into the DynamoDB table
    docClient.put(params, (err, data) => {
        if (err) {
            console.error("Error adding the edited event:", err);
        } else {
            console.log("Edited event added successfully:", data);
        }
    });
}
