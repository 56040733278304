<script setup>
import { onMounted } from 'vue'
import useThemeStore from '@/stores/theme'
import { useMeta } from 'vue-meta'

const themeStore = useThemeStore()

onMounted(async () => {
  themeStore.fetch()
})

useMeta({
  title: 'TLDRMap',
  meta: [
    { property: 'og:title', content: 'TLDRMap' },
    { property: 'og:description', content: 'You think the news just fell out of a coconut tree? World Updates & Info On The Go.' },
    { property: 'og:image', content: 'https://tldrmap.com/TLDRMapLogo.png' },
    { property: 'og:url', content: 'https://tldrmap.com/' },
    { property: 'og:type', content: 'website' }
  ]
})

</script>


<template>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <router-view />
</template>

<style>
html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box; 
}
</style>