import { defineStore } from 'pinia';
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "../firebaseConfig"; // Adjust the path if necessary

// Function to get user document from Firestore
async function getUserDocument(uid) {
    const userDoc = await getDoc(doc(db, "users", uid));
    if (userDoc.exists()) {
        return userDoc.data();
    } else {
        console.error("No such document!");
        return null;
    }
}

export const useUserStore = defineStore({
    id: 'userStore',
    state: () => ({
        currentUser: null,
        uid: '',
    }),
    actions: {
        async fetchUser() {
            onAuthStateChanged(auth, async (user) => {
                if (user) {
                    this.currentUser = await getUserDocument(user.uid);
                    this.uid = user.uid;
                } else {
                    this.currentUser = null;
                    this.uid = '';
                }
            });
        }
    }
});
