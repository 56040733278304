import { toRaw } from 'vue'
import L from "leaflet";
import { computeCentroid } from "@/services/computation/computeCentroidCalc"

export function applyCombatTypeFilter(context) {
    context.circleGroup.clearLayers();
    context.selectedCountryCode = "xx"
    context.selectedCountryName = "None Selected"
    context.selectedProxy = "none"

    if (context.selectedResourceMap) { context.selectedResourceMap.removeFrom(context.map) }
    context.decoratorLayers.forEach(layerObj => {
        context.map.removeLayer(layerObj.polyline);
        context.map.removeLayer(layerObj.decorator);
        context.map.removeLayer(layerObj.fromMarker);
        context.map.removeLayer(layerObj.toMarker);
    });

    if (context.map) {
        context.map.eachLayer((layer) => {
            if (layer.feature && layer.feature.properties) {
                const isVisibleByRegion = (context.selectedRegion === 'all' || layer.feature.properties.Region === context.selectedRegion);
                const isVisibleByResource = (context.selectedResource === 'none' || (layer.feature.properties.Resources && layer.feature.properties.Resources.includes(context.selectedResource)));
                const isVisibleByTypeOfConflict = (context.selectedTypeOfConflict === 'none' || layer.feature.properties.TypeOfConflict === context.selectedTypeOfConflict);
                const isVisibleByCombatType = (context.selectedCombatType === 'none' || context.selectedCombatType === 'all' || (layer.feature.properties.ConflictName == context.selectedCombatType));

                if (isVisibleByRegion && isVisibleByResource && isVisibleByTypeOfConflict && isVisibleByCombatType) {
                    layer._path.style.display = "";

                    if (context.selectedCombatType !== 'none' && layer.feature.properties.CombatTypes) {
                        // Iterate through each combat type
                        layer.feature.properties.CombatTypes.forEach(combat => {
                            const intensity = combat.Intensity || 1;
                            const color = getColorFromIntensity(intensity);
                            const circleSize = intensity * 25000;

                            const majorEquipmentList = combat.MajorEquipment
                                ? combat.MajorEquipment.map(equipment => `<li>${equipment}</li>`).join('')
                                : 'None';

                            let intensityDescription;
                            if (combat.Intensity >= 1 && combat.Intensity <= 3) {
                                intensityDescription = 'Low';
                            } else if (combat.Intensity >= 4 && combat.Intensity <= 7) {
                                intensityDescription = 'Medium';
                            } else if (combat.Intensity >= 8 && combat.Intensity <= 10) {
                                intensityDescription = 'High';
                            } else {
                                intensityDescription = 'Unknown'; // Just in case there's a value outside of the given range
                            }

                            const popupContent = `
                                    <div class="global-news-item">
                                        <div class="global-news-title">${combat.Name}</div>
                                        <div class="global-news-content">
                                            <div class="global-news-text">
                                                <strong>Description:</strong> ${combat.Description ? combat.Description : `${combat.Name} is a non-state actor in the ${layer.feature.properties.ConflictName}.`}<br>
                                                <strong>Major Equipment:</strong> <ul>${majorEquipmentList}</ul><br>
                                                <strong>Intensity:</strong> ${intensityDescription}
                                            </div>
                                        </div>
                                    </div>`;

                            let coordinates = layer.toGeoJSON().geometry.coordinates[0];
                            let avgLat, avgLon;

                            // Check if "Coordinates" property exists in the current update and if its first index is not ""
                            if (combat.Coordinates.Lat !== "") {
                                [avgLat, avgLon] = [combat.Coordinates.Lat, combat.Coordinates.Lng];
                            } else {
                                [avgLat, avgLon] = computeCentroid(coordinates);
                            }

                            // Create the circle based on combat coordinates
                            const circle = L.circle([avgLat, avgLon], {
                                color: color,
                                fillColor: color,
                                fillOpacity: 0.4,
                                radius: circleSize
                            }).bindPopup(popupContent);
                            context.circleGroup.addLayer(circle);
                        });

                        context.circleGroup.addTo(toRaw(context.map));
                    }
                }
                else {
                    layer._path.style.display = "none";
                }
            }
        });
        context.markers.forEach(marker => {
            const conflict = marker.options.properties;
            const isVisibleByResource = (context.selectedResource === 'none' || (conflict.Resources && conflict.Resources.includes(context.selectedResource)));
            const isVisibleByRegion = (context.selectedRegion === 'all' || conflict.RegionOfConflict === context.selectedRegion);
            const isVisibleByTypeOfConflict = (context.selectedTypeOfConflict === 'none' || conflict.TypeOfConflict === context.selectedTypeOfConflict);
            const isVisibleByCombatType = (context.selectedCombatType === 'none' || context.selectedCombatType === 'all' || (conflict.CombatTypes && conflict.CombatTypes.some(combat => combat.CombatType === context.selectedCombatType)));
            if (isVisibleByRegion && isVisibleByResource && isVisibleByTypeOfConflict && isVisibleByCombatType && marker.options.customOptions && marker.options.customOptions.flash) {
                marker._icon.style.display = "";
                if (marker._shadow) {
                    marker._shadow.style.display = "";
                }
            } else {
                marker._icon.style.display = "none";
                if (marker._shadow) {
                    marker._shadow.style.display = "none";
                }
            }
        });
    }
}
function getColorFromIntensity(intensity) {
    const colors = [
        "#FFEBE6",
        "#FFD6CF",
        "#FFBFAA",
        "#FFA087",
        "#FF7A6A",
        "#FF534D",
        "#FF2D30",
        "#E51015",
        "#B9000C",
        "#8C0004"
    ];
    return colors[intensity - 1] || colors[0];
}