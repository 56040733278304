// AWS Import
const AWS = require("aws-sdk");
import useThemeStore from '@/stores/theme';

// Various Computation/Method Imports
import { getConflictUpdatesSetup, getGlobalUpdates } from "@/services/map/updateSetup.js";
import { geoJSONSetup } from "@/services/map/geoJSONsetup.js"

// AWS Configuration
export function AWSConfig(context) {
    const docClient = new AWS.DynamoDB.DocumentClient();

    const table_name = "TLDRMapConflicts";

    // Operating with Each Conflict
    getAllConflicts(docClient, table_name)
        .then(conflicts => {
            context.compiledConflicts = conflicts;

            // Iterate through the dictionary and add markers for conflicts
            conflicts.forEach(async (conflict) => {
                setupConflictTab(conflict, context)
            });
        })
        .catch(err => {
            console.error("Error fetching data:", err);
        });

    getGlobalUpdates(docClient, context)
}

// Method To Get All Conflicts From Table
export async function getAllConflicts(docClient, table_name) {
    let params = {
        TableName: table_name,
    };

    let allConflicts = [];
    let data;

    do {
        data = await docClient.scan(params).promise();
        allConflicts.push(...data.Items);

        params.ExclusiveStartKey = data.LastEvaluatedKey;
    } while (typeof data.LastEvaluatedKey !== "undefined");

    // Special Sorting for Mexico
    allConflicts.sort((a, b) => {
        if (a.ConflictName === "Mexican Drug War") return -1;
        if (b.ConflictName === "Zapatista Conflict") return 1;
        return 0;
    });

    return allConflicts;
}

function setupConflictTab(conflict, context) {
    if (!conflict.ConflictZoneMap) {
        console.error("ConflictZoneMap is missing for:", conflict);
        return;
    }
    // Add zone
    const conflictZone = conflict.ConflictZoneMap.toString().replace(/\\n/g, "\\n").replace(/\\'/g, "\\'").replace(/\\"/g, '\\"').replace(/\\&/g, "\\&").replace(/\\r/g, "\\r").replace(/\\t/g, "\\t").replace(/\\b/g, "\\b").replace(/\\f/g, "\\f"); // Remove non-printable and other non-valid JSON characters s = s.replace(/[\u0000-\u0019]+/g,"");
    let parsedData = JSON.parse(conflictZone);

    context.selectedConflictType = conflict.TypeOfConflict;

    setUpGeoJSONLayerType(context, parsedData, conflict);
    getConflictUpdatesSetup(conflict, context, parsedData)
}

export function setUpGeoJSONLayerType(context, parsedData, conflict) {
    const themeStore = useThemeStore();
    const theme = themeStore.theme;
    // *For Each Type of Conflict Type, Add Corresponding Layer*
    const GEOJSON = geoJSONSetup(parsedData, context.map, conflict, theme);
    context.addGeoJSONClick(GEOJSON, conflict);
}
