// main.js
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import GlobalNavbar from './components/Navbar.vue';
import VTooltip from 'v-tooltip';
import './assets/main.css';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import { createMetaManager } from 'vue-meta';
import Tooltip from 'primevue/tooltip';
import configureAWS from './services/utils/awsConfig';
import VueApexCharts from "vue3-apexcharts";

const initApp = async () => {
    try {
        await configureAWS(); // Wait for AWS configuration to complete
        const app = createApp(App).use(router);
        app.use(createPinia());
        app.use(VueTippy, { directive: 'tippy', component: 'tippy' });
        app.component('GlobalNavbar', GlobalNavbar);
        app.use(VTooltip);
        app.use(createMetaManager());
        app.use(VueApexCharts);
        app.directive('tooltip', Tooltip);
        app.mount('#app');
    } catch (error) {
        console.error("Failed to configure AWS:", error);
        // Handle the error appropriately, maybe show an error message to the user
    }
};

initApp();
