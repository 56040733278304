import { toRaw } from 'vue'
import L from "leaflet";
import { kml } from "@tmcw/togeojson";

export async function applyResourceFilter(context) {
    context.selectedCountryCode = "xx"
    context.selectedCountryName = "None Selected"
    context.selectedProxy = "none"

    if (context.selectedResourceMap) { context.selectedResourceMap.removeFrom(context.map) }
    context.decoratorLayers.forEach(layerObj => {
        context.map.removeLayer(layerObj.polyline);
        context.map.removeLayer(layerObj.decorator);
        context.map.removeLayer(layerObj.fromMarker);
        context.map.removeLayer(layerObj.toMarker);
    });

    if (context.map) {

        context.map.eachLayer((layer) => {
            if (layer.feature && layer.feature.properties) {
                const isVisibleByRegion = (context.selectedRegion === 'all' || layer.feature.properties.Region === context.selectedRegion);
                const isVisibleByTypeOfConflict = (context.selectedTypeOfConflict === 'none' || layer.feature.properties.TypeOfConflict === context.selectedTypeOfConflict);
                const isVisibleByCombatType = (context.selectedCombatType === 'none' || context.selectedCombatType === 'all' || (layer.feature.properties.ConflictName == context.selectedCombatType));

                // Check if the selected resource is within the Resources array
                const hasSelectedResource = (context.selectedResource === 'none' || (layer.feature.properties.Resources && layer.feature.properties.Resources.includes(context.selectedResource)));

                if(layer._path) {
                    if (hasSelectedResource && isVisibleByRegion && isVisibleByTypeOfConflict && isVisibleByCombatType) {
                        layer._path.style.display = "";  // Show the layer
                    } else {
                        layer._path.style.display = "none";  // Hide the layer
                    }
                }
            }
        });
        context.markers.forEach(marker => {
            const conflict = marker.options.properties;
            const isVisibleByTypeOfConflict = (context.selectedTypeOfConflict === 'none' || conflict.TypeOfConflict === context.selectedTypeOfConflict);
            const isVisibleByRegion = (context.selectedRegion === 'all' || conflict.RegionOfConflict === context.selectedRegion);
            const isVisibleByCombatType = (context.selectedCombatType === 'none' || context.selectedCombatType === 'all' || (conflict.ConflictName == context.selectedCombatType));
            const hasSelectedResource = (context.selectedResource === 'none' || (conflict.Resources && conflict.Resources.includes(context.selectedResource)));
            if (hasSelectedResource && isVisibleByRegion && isVisibleByTypeOfConflict && isVisibleByCombatType && marker.options.customOptions && marker.options.customOptions.flash) {
                marker._icon.style.display = "";
                if (marker._shadow) {
                    marker._shadow.style.display = "";
                }
            } else {
                marker._icon.style.display = "none";
                if (marker._shadow) {
                    marker._shadow.style.display = "none";
                }
            }
        });

        // Resource Layer
        const response = await fetch("maps/resources/" + context.selectedResource + ".kml");
        if (response.ok) {

            // Using KML --> GeoJSON Converter
            const text = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(text, "text/xml");
            const geojson = kml(doc);

            // Adding Special Styles 
            context.selectedResourceMap = L.geoJSON(geojson, {
                style: function (feature) {
                    return {
                        fillColor: feature.properties.fill,
                        fillOpacity: feature.properties['fill-opacity'],
                        color: feature.properties.stroke,
                        opacity: feature.properties['stroke-opacity'],
                        weight: 1
                    };
                },
                onEachFeature: function (feature, layer) {
                    // Check if the feature is a point
                    if (feature.geometry.type !== "Point") {
                        // Check if the feature has a property named 'name'
                        if (feature.properties && feature.properties.name) {
                            // Bind a tooltip to the layer with the feature's name
                            layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto", className: 'kml-map-tooltip' });
                        }
                    }
                },
                filter: function (feature) {
                    // Filter out point features
                    return feature.geometry.type !== "Point";
                }
            }).addTo(toRaw(context.map));

            // Save The Hidden Conflict Zone Layer So It Can Return When User Clicks Off KML
        }
    }
}

export async function applyResourceFilterUSA(context) {
    // Resource Layer
    const response = await fetch("maps/resources/" + context.selectedResource + ".kml");
    if (response.ok) {

        // Using KML --> GeoJSON Converter
        const text = await response.text();
        const parser = new DOMParser();
        const doc = parser.parseFromString(text, "text/xml");
        const geojson = kml(doc);

        // Adding Special Styles 
        context.selectedResourceMap = L.geoJSON(geojson, {
            style: function (feature) {
                return {
                    fillColor: feature.properties.fill,
                    fillOpacity: feature.properties['fill-opacity'],
                    color: feature.properties.stroke,
                    opacity: feature.properties['stroke-opacity'],
                    weight: 1
                };
            },
            onEachFeature: function (feature, layer) {
                // Check if the feature is a point
                if (feature.geometry.type !== "Point") {
                    // Check if the feature has a property named 'name'
                    if (feature.properties && feature.properties.name) {
                        // Bind a tooltip to the layer with the feature's name
                        layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto", className: 'kml-map-tooltip' });
                    }
                }
            },
            filter: function (feature) {
                // Filter out point features
                return feature.geometry.type !== "Point";
            }
        }).addTo(toRaw(context.map));

        // Save The Hidden Conflict Zone Layer So It Can Return When User Clicks Off KML
    }
}
