// awsConfig.js
import AWS from 'aws-sdk';

const configureAWS = async () => {
    AWS.config.update({
        region: 'us-east-2',
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: process.env.VUE_APP_AWS_ACCESS_KEY_ID,
        }),
    });

    // Refresh credentials if they are expired
    await new Promise((resolve, reject) => {
        AWS.config.credentials.refresh((error) => {
            if (error) {
                console.error('Error refreshing credentials:', error);
                reject(error);
            } else {
                console.log('Credentials refreshed successfully');
                resolve();
            }
        });
    });
};

export default configureAWS;
