export function timeSince(date) {
    // Get the current time in GMT
    const now = new Date();
    const gmtNow = new Date(now.toISOString());

    // Calculate the difference in seconds
    const secondsPast = Math.abs((gmtNow.getTime() - date.getTime()) / 1000);

    // Determine the appropriate time string
    let timeString;
    if (secondsPast < 60) {
        timeString = `${Math.floor(secondsPast)} seconds ago`;
    } else if (secondsPast < 3600) {
        timeString = `${Math.floor(secondsPast / 60)} minutes ago`;
    } else if (secondsPast < 86400) {
        timeString = `${Math.floor(secondsPast / 3600)} hours ago`;
    } else {
        const daysPassed = Math.floor(secondsPast / 86400);
        timeString = `${daysPassed} day${daysPassed !== 1 ? 's' : ''} ago`;
    }

    // Return the array with the time string and secondsPast
    return [timeString, secondsPast];
}
