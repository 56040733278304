import { defineStore } from 'pinia';

const useMapModeStore = defineStore({
    id: 'mapModeStore',
    state: () => ({
        mode: "base",
    }),
    actions: {
        change(mode) {
            this.mode = mode;
        }
    }
});
export default useMapModeStore