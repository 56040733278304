<template>
<div class="absolute bg-[#34495E] dark:bg-[#111827] bg-auto bg-opacity-75 z-50 right-0 top-0 mt-10 mr-10 rounded-md w-auto h-auto resize-y transition-all duration-300 ease-in-out" id="dropdown-content" v-if="isDropdownFilterOpen">
  <div class="bg-[#34495E] dark:bg-[#111827] shadow-md rounded-lg p-5 text-center w-full max-h-[90vh] border border-gray-700 dark:border-gray-900 rounded-md text-base text-gray-300 dark:text-gray-200 cursor-pointer transition-all duration-300 ease-in-out">
        <h1 class="text-2xl mb-5 text-gray-300 dark:text-gray-200">Filter Menu</h1>
        <div class="flex flex-col space-y-2">
            <select v-model="selectedResource" @change="applyResourceFilter" class="p-3 bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md text-base text-gray-300 dark:text-gray-200 cursor-pointer transition duration-300">
                <option value="none">All Resources</option>
                <option v-for="resource in resources" :key="resource" :value="resource">{{ resource }}</option>
            </select>
            
            <select v-model="selectedCombatType" @change="applyCombatTypeFilter" class="p-3 bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md text-base text-gray-300 dark:text-gray-200 cursor-pointer transition duration-300">
                <option value="none">No Non-State Actors</option>
                <option value="all">All Non-State Actors</option>
                <option :key="type" :value="type" v-for="type in combatTypes">{{ type }}</option>
            </select>
            
            <div class="flex items-center w-[100%] mb-6 relative">
                <select v-model="selectedProxy" @change="applyProxyFilter(0)" class="w-full p-3 bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md text-base text-gray-300 dark:text-gray-200 cursor-pointer transition duration-300">
                    <option value="none">No Foreign Influencers</option>
                    <option v-for="conflict in proxyWars" :key="conflict" :value="conflict">{{ conflict }}</option>
                </select>	
            

                <div class="flex-[20%] relative ml-2">
                    <div @click="toggleDropdown" class="flex items-center cursor-pointer p-3 bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md text-base text-gray-300 dark:text-gray-200 transition duration-300">
                        <span :class="['fi', 'fi-' + selectedCountryCode]"></span>
                        <span class="ml-auto caret"></span>
                    </div>
                </div>
                <div class="scrollbar-thin scrollbar-thumb-gray-300 dark:scrollbar-thumb-gray-500 scrollbar-track-gray-800 dark:scrollbar-track-gray-700 absolute top-full left-0 w-full bg-gray-800 dark:bg-gray-900 border border-gray-700 dark:border-gray-600 rounded-md mt-2 overflow-x-auto whitespace-nowrap" v-show="dropdownOpen">
                    <div class="inline-block border border-gray-700 dark:border-gray-600 cursor-pointer p-2" v-for="country in countries" :key="country.code" @click="selectCountry(country)">
                        <span :class="['fi', 'fi-' + country.code]"></span>
                    </div>
                </div>
            </div>
            <!-- <div class="bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md p-4 flex flex-col items-center space-y-2">
                <h3 class="text-lg text-gray-300 dark:text-gray-200">Special Filters</h3>
                <div class="flex justify-around w-full">
                    <div class="w-1/2 flex justify-center items-center border border-yellow-400 dark:border-yellow-500 m-1 relative">
                        <i class="fas fa-medal text-2xl text-yellow-400 dark:text-yellow-500 p-1"></i>
                        <div class="absolute top-full left-0 bg-gradient-to-b from-blue-500 dark:from-blue-600 to-blue-600 dark:to-blue-700 border border-black p-2 hidden z-10 w-4/5 text-sm" v-if="showHasRegionalNoviceRankTooltip">
                            Rank is at least Regional Novice.<br /><br />Level up through edit system.
                        </div>
                    </div>
                    <div class="w-1/2 flex justify-center items-center border border-yellow-400 dark:border-yellow-500 m-1 relative">
                        <i class="fas fa-crown text-2xl text-yellow-400 dark:text-yellow-500 p-1"></i>
                        <div class="absolute top-full left-1/4 bg-gradient-to-b from-blue-500 dark:from-blue-600 to-blue-600 dark:to-blue-700 border border-black p-2 hidden z-10 w-4/5 text-sm" v-if="showHasSubscribedTooltip">
                            Has Subscribed to TLDRMap+<br /><br />Upgrade for only 0.99/month!
                        </div>
                    </div>
                </div>
            </div> -->
            <br/>
            <div class="flex items-center justify-center">
                <CanvasDrawing :map="map" :markers="markers" v-bind:context="context" @updateContext="$emit('updateContext', $event)"></CanvasDrawing>
            </div>
            <br/>
            <select v-model="selectedRegion" @change="applyRegionFilter" class="p-3 bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md text-base text-gray-300 dark:text-gray-200 cursor-pointer transition duration-300">
                <option value="all">All Regions</option>
                <option v-for="region in regions" :key="region" :value="region"><b>{{ region }}</b></option>
            </select>
            <select v-model="selectedTypeOfConflict" @change="applyTypeOfConflictFilter" class="p-3 bg-gray-800 dark:bg-gray-800 border border-gray-700 dark:border-gray-600 rounded-md text-base text-gray-300 dark:text-gray-200 cursor-pointer transition duration-300">
                <option value="none">All Conflict Types</option>
                <option v-for="conflictType in conflictTypes" :key="conflictType" :value="conflictType"><b>{{ conflictType }}</b></option>
            </select>
        </div>
    </div>
</div>
</template>
		
<script>
import L from "leaflet";
import { ref, onMounted, computed  } from "vue";
// import { getAuth } from "firebase/auth";
// import { getFirestore, doc, getDoc } from "firebase/firestore";
import '@fortawesome/fontawesome-free/css/all.css'
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { useUserStore } from '@/stores/currentUser';
// import {
//     Listbox,
//     ListboxButton,
//     ListboxOptions,
//     ListboxOption,
//   } from '@headlessui/vue'

import CanvasDrawing from "@/components/map/CanvasDrawing.vue"
import useFilterDropdownStore from '@/stores/filterDropdown';
import { applyCombatTypeFilter } from '@/services/filters/applyCombatTypeFilter.js';
import { applyProxyFilter, toggleDropdown, selectCountry } from '@/services/filters/applyProxyFilter.js';
import { applyRegionFilter } from '@/services/filters/applyRegionFilter.js';
import { applyResourceFilter } from '@/services/filters/applyResourceFilter.js';
import { applyTypeOfConflictFilter } from '@/services/filters/applyTypeOfConflictFilter.js';
import { getAllConflicts } from '@/services/conflict-tab/AWSConfig.js';

const AWS = require("aws-sdk");

export default {
    data() {
        return {
            selectedRegion: 'all',
            selectedResource: 'none',
            selectedTypeOfConflict: 'none',
            selectedProxy: 'none',
            selectedCombatType: 'none',
            regions: ["Mesoamerica", "South America", "Eastern Europe", "North Africa", "Sahel", "Central Africa", "Eastern Africa", "Central Asia", "South Asia", "Middle East", "East Asia", "North America", "Western Europe"],
            resources: ["Oil", "Natural Gas", "Drugs", "Gold", "Rare Earth Metals"],
            selectedResourceMap: null,
            conflictTypes: ["Invasion", "Civil War", "Frozen Conflict", "Ethnic Conflict", "Insurgency", "Territorial Dispute"],
            selectedCountryName: "",
            selectedCountryCode: "xx",
            dropdownOpen: false,
            countries: [
                { name: "None Selected", code: "xx" },
                { name: "United States of America", code: "us" }, 
                { name: "Russia", code: "ru" }, 
                { name: "China", code: "cn" }, 
                { name: "France", code: "fr" }, 
                { name: "United Kingdom", code: "gb" }, 
                { name: "Saudi Arabia", code: "sa" }, 
                { name: "Iran", code: "ir" }, 
                { name: "India", code: "in" }, 
                { name: "Pakistan", code: "pk" }
            ],
            geoLayer: null,
            decoratorLayers: [],
            circleGroup: L.layerGroup(),
            markers: [],
            
            showHasRegionalNoviceRankTooltip: false,
            showHasSubscribedTooltip: false,
            showDropdownFilterOpen: false,

        };
    },
    components: {
        CanvasDrawing,
    },
    props: {
        map: {
            type: Object,
            required: true
        },
        context: {
            type: Object,
            required: true
        },
    },
    setup() {
        // const db = getFirestore();
        let isPremiumFiltersVisible = ref(true); // change to false for premium mode
        
        const userStore = useUserStore();

        const docClient = new AWS.DynamoDB.DocumentClient();
        const table_name = "TLDRMapConflicts";

        const proxyWars = ref([]);
        const combatTypes = ref([]);

        const fetchConflicts = async () => {
            const conflicts = await getAllConflicts(docClient, table_name);

            const allProxyWars = new Set();
            const allCombatTypes = new Set();

            conflicts.forEach(conflict => {
                if (conflict.Proxies) {
                    allProxyWars.add(conflict.ConflictName);
                }
                if (conflict.CombatTypes) {
                    allCombatTypes.add(conflict.ConflictName);
                }
            });

            proxyWars.value = Array.from(allProxyWars);
            combatTypes.value = Array.from(allCombatTypes);
        };
        
        onMounted(async () => {
            await fetchConflicts();
            await userStore.fetchUser();
            if (userStore.currentUser) {
                if (userStore.currentUser.hasAccessToFilters) {
                    isPremiumFiltersVisible.value = true;
                }
            }
            
        });

        
        
        
        // Fetch user document from Firestore
        // const getUserDocument = async () => {
        // 	if (!userStore.uid) return null;
        // 	try {
        // 		const userDocument = await getDoc(doc(db, 'users', userStore.uid));
        // 		return { uid: userStore.uid, ...userDocument.data() };
        // 	} catch (error) {
        // 		console.error("Error fetching user", error);
        // 	}
        // };
        
        const filterDropdownStore = useFilterDropdownStore();
        const isDropdownFilterOpen = computed(() => filterDropdownStore.isDropdownFilterOpen);
        
        return { isPremiumFiltersVisible, isDropdownFilterOpen, proxyWars,combatTypes }
    },
    async mounted() {
        
        
    },
    computed: {
        regionsOptions() {
            return this.regions.map(region => ({ value: region, label: region }));
        },
        conflictTypesOptions() {
            return this.conflictTypes.map(conflictType => ({ value: conflictType, label: conflictType }));
        },
        resourcesOptions() {
            return this.resources.map(resource => ({ value: resource, label: resource }));
        },
    },
    methods: {
        handleUpdateContext(newContext) {
            this.$emit('updateContext', newContext);
        },

        // Binding Filter Functions
        applyCombatTypeFilter() {
            applyCombatTypeFilter(this);
        },
        applyProxyFilter(int) {
            applyProxyFilter(this, int);
        },
        applyRegionFilter() {
            applyRegionFilter(this);
        },
        applyResourceFilter() {
            applyResourceFilter(this);
        },
        applyTypeOfConflictFilter() {
            applyTypeOfConflictFilter(this);
        },
        toggleDropdown() {
            toggleDropdown(this);
        },
        selectCountry(country) {
            selectCountry(this, country);
        },
    },
    watch: {
        map(newValue, oldValue) {
            if (newValue !== oldValue) {
                newValue.on('layeradd', (e) => { // Notice the use of arrow function
                    if (e.layer instanceof L.Marker && e.layer.options.properties) {
                        this.markers.push(e.layer); 
                    }
                });
            }
        }
    }
};
</script>