<template>
    <GlobalNavbar />
    <div class="min-h-screen bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100 p-8">
        <h1 class="text-4xl font-bold mb-12 mt-20 text-center">Updates</h1>
        <div v-if="isLoading" class="text-center">Loading...</div>
        <div v-else>
            <div class="flex justify-center mb-8">
                <select v-model="selectedConflict" class="p-3 text-lg bg-gray-200 dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
                    <option disabled value="">Please select a conflict</option>
                    <option value="">All</option>
                    <option value="Global">Global</option>
                    <option v-for="conflictName in uniqueConflictNames" :key="conflictName" :value="conflictName">
                        {{ conflictName }}
                    </option>
                </select>
            </div>
            <div class="space-y-6">
                <div v-for="(update, index) in filteredUpdates.length ? filteredUpdates : updates" :key="index" class="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-lg transition-transform transform hover:-translate-y-1 hover:shadow-xl">
                    <div class="text-2xl font-semibold mb-2">{{ update.ConflictName }}</div>
                    <div class="text-gray-600 dark:text-gray-400 relative group">
                        <p class="mb-2">{{ update.Update }}</p>
                        <span class="block text-right text-sm">{{ new Date(update.DateTime).toLocaleString() }}</span>
                        <div class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-full bg-gray-900 text-white text-xs rounded-lg p-2 opacity-0 group-hover:opacity-100 transition-opacity">
                            {{ update.Desc }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import GlobalNavbar from "@/components/Navbar.vue";
import { stripHTML } from "@/services/computation/stripHTML.js";
import { getAllConflicts } from "@/services/conflict-tab/AWSConfig.js";

const AWS = require("aws-sdk");

const updates = ref([]);
const isLoading = ref(true);
const selectedConflict = ref("");

onMounted(() => {
    let existingUpdates = [];
        
    const docClient = new AWS.DynamoDB.DocumentClient();
    const table_name = "TLDRMapConflicts";
    
    getAllConflicts(docClient, table_name)
    .then(conflicts => {
        const conflictUpdates = conflicts.flatMap(conflict => conflict.Updates.map(update => ({
            Update: update.Update,
            DateTime: update.DateTime,
            ConflictName: conflict.ConflictName,
            Desc: stripHTML(update.UpdateDesc ? update.UpdateDesc : update.Update),
        })));
        let sortedData = conflictUpdates.sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime));
        
        existingUpdates = sortedData;
        
        const global_updates_table_name = "TLDRMapGlobalNews";
        const newParams = { TableName: global_updates_table_name };
        
        docClient.scan(newParams, (err, data) => {
            if (err) {
                console.error("Error fetching data:", err);
            } else {
                const updatesData = data.Items;
                
                const conflictUpdates = updatesData.map(update => ({
                    Update: update.Update,
                    DateTime: update.DateTime,
                    ConflictName: "Global",
                    Desc: stripHTML(update.UpdateDesc ? update.UpdateDesc : update.Update),
                }));
                
                const combinedUpdates = [...existingUpdates, ...conflictUpdates];
                updates.value = combinedUpdates.sort((a, b) => new Date(b.DateTime) - new Date(a.DateTime));
                isLoading.value = false;
            }
        });
    })
    .catch(err => console.error("Error fetching data:", err));
});

const filteredUpdates = computed(() => {
    return updates.value.filter(update => update.ConflictName === selectedConflict.value);
});

const uniqueConflictNames = computed(() => {
    const conflictNamesSet = new Set(updates.value.map(update => update.ConflictName));
    return [...conflictNamesSet].filter(name => name !== "Global");
});
</script>

