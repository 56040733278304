import { defineStore } from 'pinia';

const useFilterDropdownStore = defineStore({
    id: 'filterDropdownStore',
    state: () => ({
        isDropdownFilterOpen: false,
        isDataCenterDropdownFilterOpen: true,
        isDataCenterMathModeDropdownFilterOpen: true,
        isPresidentsTabOpen: true,
        isSenateTabOpen: false,
        isElectionsDropdownFilterOpen: true,
    }),
    actions: {
        toggleDropdownFilter() {
            this.isDropdownFilterOpen = !this.isDropdownFilterOpen;
        },
        toggleDataCenterDropdownFilter() {
            this.isDataCenterDropdownFilterOpen = !this.isDataCenterDropdownFilterOpen;
        },
        toggleDataCenterMathModeDropdownFilter() {
            this.isDataCenterMathModeDropdownFilterOpen = !this.isDataCenterMathModeDropdownFilterOpen;
        },
        togglePresidentsTab() {
            this.isPresidentsTabOpen = !this.isPresidentsTabOpen;
        },
        openPresidentsTab() {
            this.isPresidentsTabOpen = true;
        }, 
        openSenateTab() {
            this.isSenateTabOpen = true;
        },
        toggleSenateTab() {
            this.isSenateTabOpen = !this.isSenateTabOpen;
        },
        toggleElectionsDropdownFilter() {
            this.isElectionsDropdownFilterOpen = !this.isElectionsDropdownFilterOpen;
        }
    }
});
export default useFilterDropdownStore