<template>
  <button
    :class="[
      'relative w-full py-2 px-4 text-white text-base font-bold rounded transition-all duration-300',
      isFollowing ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-700 hover:bg-gray-800',
      'before:content-[\'\'] before:absolute before:inset-0 before:bg-[radial-gradient(circle,#ffffff,transparent_1px),radial-gradient(circle,#ffffff,transparent_1px)] before:bg-[0_0,1px_1px] before:bg-size-[2px_2px] before:opacity-10 before:pointer-events-none before:mix-blend-overlay',
      'disabled:bg-[#344b6e] disabled:cursor-not-allowed'
    ]"
    :disabled="!isLoggedIn"
    @click="toggleFollow(props.selectedConflict)"
    v-tippy="{ placement: 'bottom', content: isFollowing ? 'You Are Already Following Conflict' : 'Click to Follow Conflict' }"
  >
    <span v-if="!isFollowing">+</span>
    <span v-else>
      <svg class="inline-block w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
    </span>
  </button>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useUserStore } from '@/stores/currentUser';
import { doc, updateDoc, arrayUnion, arrayRemove, getDoc, getFirestore } from 'firebase/firestore';

const db = getFirestore();
const userStore = useUserStore();
const isLoggedIn = ref(!!userStore.uid);
const isFollowing = ref(false);

const props = defineProps({
  selectedConflict: String,
});

const checkIfFollowing = async () => {
  if (!isLoggedIn.value) return;

  const userRef = doc(db, 'users', userStore.uid);
  const userSnap = await getDoc(userRef);
  
  if (userSnap.exists()) {
    const data = userSnap.data();
    isFollowing.value = data.subscribedConflicts?.includes(props.selectedConflict);
    console.log(isFollowing.value)
  }
};

const toggleFollow = async (selectedConflict) => {
  if (!isLoggedIn.value) return;

  const userRef = doc(db, 'users', userStore.uid);

  if (isFollowing.value) {
    // Unfollow the conflict
    await updateDoc(userRef, {
      subscribedConflicts: arrayRemove(selectedConflict)
    });
    isFollowing.value = false;
  } else {
    // Follow the conflict
    await updateDoc(userRef, {
      subscribedConflicts: arrayUnion(selectedConflict)
    });
    isFollowing.value = true;
  }
};

// Watch for changes to selectedConflict and re-run checkIfFollowing when it changes
watch(() => props.selectedConflict, () => {
  checkIfFollowing();
});

// Run the check on component mount
checkIfFollowing();
</script>


<style scoped>
button {
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: background-color 0.3s ease;
}
</style>
