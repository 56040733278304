import { getFirestore, doc, updateDoc, arrayUnion, getDoc } from 'firebase/firestore';
import { useUserStore } from '@/stores/currentUser';

export function saveTimelinePost(index, conflictName) {
    // Get the current user store instance
    const userStore = useUserStore();

    if(!userStore.uid) {
        return;
    }
    // Get Firestore instance
    const db = getFirestore();

    // Reference to the current user's document
    const userRef = doc(db, 'users', userStore.uid);

    // Create the dictionary (object) to save
    const newPost = {
        index: index,
        conflictName: conflictName
    };

    // Update the savedTimelinePosts array by adding the new post
    updateDoc(userRef, {
        savedTimelinePosts: arrayUnion(newPost)
    }).then(() => {
        console.log('Timeline post saved successfully!');
    }).catch(error => {
        console.error('Error saving timeline post:', error);
    });
}

export async function removeTimelinePost(index, conflictName) {
    // Get the current user store instance
    const userStore = useUserStore();

    if(!userStore.uid) {
        return;
    }
    // Get Firestore instance
    const db = getFirestore();

    // Reference to the current user's document
    const userRef = doc(db, 'users', userStore.uid);

    try {
        // Fetch the current user's document
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            // Get the current savedTimelinePosts array
            const savedTimelinePosts = docSnap.data().savedTimelinePosts || [];

            // Filter out the post that matches the index and conflictName
            const updatedPosts = savedTimelinePosts.filter(
                post => post.index !== index || post.conflictName !== conflictName
            );

            // Update the savedTimelinePosts array in Firestore
            await updateDoc(userRef, {
                savedTimelinePosts: updatedPosts
            });

            console.log('Timeline post removed successfully!');
        } else {
            console.error('No such document!');
        }
    } catch (error) {
        console.error('Error removing timeline post:', error);
    }
}


export async function checkIfHighlighted(index, conflictName) {
    // Get the current user store instance
    const userStore = useUserStore();

    if(!userStore.uid) {
        return;
    }
    // Get Firestore instance
    const db = getFirestore();

    // Reference to the current user's document
    const userRef = doc(db, 'users', userStore.uid);

    try {
        // Fetch the user's document
        const docSnap = await getDoc(userRef);

        if (docSnap.exists()) {
            const savedTimelinePosts = docSnap.data().savedTimelinePosts || [];

            // Check if the combination of index and conflictName exists in savedTimelinePosts
            return savedTimelinePosts.some(post => post.index === index && post.conflictName === conflictName);
        } else {
            console.error('No such document!');
            return false;
        }
    } catch (error) {
        console.error('Error fetching user data:', error);
        return false;
    }
}
