<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div id="app">
    <Disclosure as="nav" class="bg-sky-100 dark:bg-big-stone-500 absolute top-0 right-0 left-0 z-[500000000] w-full" v-slot="{ open }">
      <div class="px-2 sm:px-6 lg:px-8">
        <div class="relative flex h-14 items-center justify-between">
          <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <DisclosureButton class="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span class="absolute -inset-0.5" />
              <span class="sr-only">Open main menu</span>
              <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
              <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
            </DisclosureButton>
          </div>
          <div class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
            <div class="flex flex-shrink-0 items-center invisible md:visible">
              <button @click="logoClicked"><img class="h-8 w-auto" :src="isDarkMode ? 'TLDRMapLogo.png' : 'TLDRMapLogoDark.png'" alt="TLDR Map Logo"></button>
            </div>
            <div class="hidden sm:ml-6 sm:block">
              <div class="flex space-x-4">
                <router-link
                  v-for="item in filteredNav"
                  :key="item.name"
                  :to="item.path"
                  class="link hover:bg-zinc-700 hover:text-white rounded-md px-4 py-2 text-base"
                  :class="isActive(item.path)"
                  :aria-current="item.isActive ? 'page' : undefined">
                  {{ item.name }}
                </router-link>
            </div>
          </div>
        </div>
        <div class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
          <ThemeSwitcher />
          <button v-tippy="{ content: 'Toggle Filters Tab', placement: 'bottom' }" v-if = "route.path == '/'" type="button" @click="openDropdownFilter" class="dark:text-white rounded-md p-2 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-600 focus:ring-opacity-50 dark:hover:bg-zinc-700">
            <FunnelIcon class="h-6 w-6" />
          </button>
          <button v-tippy="{ content: 'Toggle Nerd Mode Tab', placement: 'bottom' }" v-if = "route.path == '/datacenter'" type="button" @click="openDataCenterMathModeDropdownFilter" class="invisible md:visible dark:text-white rounded-md p-2 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-600 focus:ring-opacity-50 dark:hover:bg-zinc-700">
            <CalculatorIcon class="h-6 w-6" />
          </button>
          <button v-tippy="{ content: 'Toggle Selection Tab', placement: 'bottom' }" v-if = "route.path == '/datacenter'" type="button" @click="openDataCenterDropdownFilter" class="dark:text-white rounded-md p-2 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-600 focus:ring-opacity-50 dark:hover:bg-zinc-700">
            <ChartBarSquareIcon class="h-6 w-6" />
          </button>  
          <button v-tippy="{ content: 'Toggle Presidents Tab', placement: 'bottom' }" v-if = "route.path == '/elections' && isMobile" type="button" @click="openElectionsPresidentsTab" class="dark:text-white rounded-md p-2 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-600 focus:ring-opacity-50 dark:hover:bg-zinc-700">
            <BuildingLibraryIcon class="h-6 w-6" />
          </button> 
          <button v-tippy="{ content: 'Toggle Elections Tab', placement: 'bottom' }" v-if = "route.path == '/elections'" type="button" @click="openElectionsDropdownFilter" class="dark:text-white rounded-md p-2 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-600 focus:ring-opacity-50 dark:hover:bg-zinc-700">
            <PresentationChartLineIcon class="h-6 w-6" />
          </button>          
          <button v-if="!userStore.currentUser" @click="showLoginModal" type="button" class="px-4 py-2 bg-indigo-500 hover:bg-indigo-600 text-gray-50 rounded-xl flex items-center gap-2">
            <ArrowLeftStartOnRectangleIcon class="h-6 w-6" />
            <span>Login</span>
          </button>
          
          <!-- <button v-if="userStore.currentUser" type="button" class="dark:text-white rounded-md p-2 hover:bg-zinc-200 focus:outline-none focus:ring-2 focus:ring-zinc-600 focus:ring-opacity-50 dark:hover:bg-zinc-700">
            <span class="sm:-inset-1.5"></span>
            <span class="sr-only">View notifications</span>
            <BellIcon class="h-6 w-6" aria-hidden="true" />
          </button> -->
          
          <Menu v-if="userStore.currentUser" as="div" class="relative ml-3">
            <div>
              <MenuButton v-tippy="{ content: 'Profile', placement: 'bottom' }" class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                <span class="absolute -inset-1.5" />
                <span class="sr-only">Open user menu</span>
                <img class="h-8 w-8 rounded-full" src="https://ionicframework.com/docs/img/demos/avatar.svg" alt="" />
              </MenuButton>
            </div>
            <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
              <MenuItems class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-zinc-700 dark:bg-zinc-700 dark:text-zinc-50">
                <MenuItem v-slot="{ active }">
                  <router-link 
                  to="/feed" 
                  :class="[active ? 'dark:hover:bg-zinc-800 dark:hover:text-white rounded-md bg-gray-100' : '', 'block px-4 py-2 text-sm']"
                  :aria-current="isActive ? 'page' : undefined">
                  Your Feed</router-link>
                </MenuItem>
                <!-- <MenuItem v-slot="{ active }">
                  <router-link 
                  to="/devpages" 
                  :class="[active ? 'dark:hover:bg-zinc-800 dark:hover:text-white rounded-md bg-gray-100' : '', 'block px-4 py-2 text-sm']"
                  :aria-current="isActive ? 'page' : undefined">
                  Dev Pages</router-link>
                </MenuItem> -->
                
                <!-- <MenuItem v-slot="{ active }">
                  <div :class="[active ? 'dark:hover:bg-zinc-800 dark:hover:text-white rounded-md bg-gray-100' : '', 'block px-4 py-2 text-sm']">
                    <button v-if="userStore.currentUser && showUpgradeButton" class="w-full text-left" @click="upgradeClick">Upgrade to TLDRMap+</button>
                    <button v-if="userStore.currentUser && !showUpgradeButton" class="w-full text-left">Customer Portal</button>
                  </div>
                </MenuItem> -->
                <MenuItem v-slot="{ active }">
                  <div :class="[active ? 'dark:hover:bg-zinc-800 dark:hover:text-white rounded-md bg-gray-100' : '', 'block px-4 py-2 text-sm']">
                    <button class="w-full text-left" @click="handleSignout">Sign out</button>
                  </div>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </div>
    </div>
    <DisclosurePanel class="sm:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2">
        <DisclosureButton v-for="item in filteredNav" :key="item.name" as="template">
          <router-link
            :to="item.path"
            :class="[item.isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'block rounded-md px-3 py-2 text-base font-medium']"
            :aria-current="item.isActive ? 'page' : undefined">
            {{ item.name }}
          </router-link>
        </DisclosureButton>
      </div>
    </DisclosurePanel>
  </Disclosure>
  </div>

  <LoginPage v-if="isLoginModalOpen" @close="closeLoginModal" @showSignupModal="showSignupModal"/>
  <SignupPage v-if="isSignupModalOpen" @close="closeSignupModal" @showLoginModal="showLoginModal"/>

  <!-- Navbar -->
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon, ArrowLeftStartOnRectangleIcon, FunnelIcon, ChartBarSquareIcon, CalculatorIcon, PresentationChartLineIcon, BuildingLibraryIcon } from '@heroicons/vue/24/outline'
import { computed, ref, onMounted } from "vue";
import { getAuth, signOut } from "firebase/auth";
import { useRoute, useRouter } from "vue-router";

//upgradeAlert
import { fetchSubscription } from "@/services/user-feed/createSub.js";
import { upgradeAlert } from "@/services/user-feed/createSub.js";
import { useUserStore } from '@/stores/currentUser';
import ThemeSwitcher from '@/components/theme-switcher.vue'
import useFilterDropdownStore from '@/stores/filterDropdown';
import useMapModeStore from '@/stores/mapMode';
import { checkMobile } from '@/services/computation/checkMobile.js';

import LoginPage from '@/views/Login.vue';
import SignupPage from '@/views/Signup.vue';
import useThemeStore from '@/stores/theme';
// import CustomerPortal from "@/components/user-feed/CustomerPortal.vue";

const auth = getAuth();
const route = useRoute();
const router = useRouter();

// const isCustomerPortalOpen = ref(false);
const isLoginModalOpen = ref(false);
const isSignupModalOpen = ref(false);
const showUpgradeButton = ref(false);

const userStore = useUserStore();

const filterDropdownStore = useFilterDropdownStore();

const mapModeStore = useMapModeStore();

const openDropdownFilter = () => {
  filterDropdownStore.toggleDropdownFilter();
};

const openDataCenterDropdownFilter = () => {
  filterDropdownStore.toggleDataCenterDropdownFilter();
};

const openDataCenterMathModeDropdownFilter = () => {
  filterDropdownStore.toggleDataCenterMathModeDropdownFilter();
};

const openElectionsPresidentsTab = () => {
  filterDropdownStore.togglePresidentsTab();
}

const openElectionsDropdownFilter = () => {
  filterDropdownStore.toggleElectionsDropdownFilter();
};


const isDarkMode = computed(() => {
  return useThemeStore().theme === 'dark' || (useThemeStore().theme === 'auto') 
})

function isActive(link) {
  const isActiveClass = route.path === link ? 'text-zinc-800 dark:text-zinc-50' : '';
  const isInactiveClass = route.path !== link ? 'text-gray-500 dark:text-slate-300' : '';
  return `${isActiveClass} ${isInactiveClass}`;
}

onMounted(async () => {  
  userStore.fetchUser();
  console.log(userStore.currentUser)
  const subscription = await fetchSubscription(userStore.uid);
  showUpgradeButton.value = !subscription;
});
const navigation = [
  { name: 'Conflicts', path: '/', class: 'link', isActive: (mapModeStore.mode === 'base'), visible: true },
  { name: 'Elections', path: '/elections', class: 'link', isActive: isActive('/elections'), visible: true },
  { name: 'Data Center', path: '/datacenter', class: 'link', isActive: isActive('/datacenter'), visible: true },
  // { name: 'Updates', path: '/updatestable', class: 'link', isActive: isActive('/updatestable'), visible: true },
  { name: 'Aid', path: '/organizations', class: 'link', isActive: isActive('/organizations'), visible: true },
  { name: 'About Us', path: '/about', class: 'link', isActive: isActive('/about'), visible: true },
  { name: 'Support Us', path: '/supportus', class: 'link', isActive: isActive('/supportus'), visible: true },
]

const filteredNav = computed(() => {
  return navigation.filter(item => item.visible)
})

const handleSignout = () => {
  signOut(auth).then(() => {
    console.log('handled sign out')
    router.push("/").then(() => location.reload());
  });
};

const showLoginModal = () => {
  isLoginModalOpen.value = true;
  isSignupModalOpen.value = false;
};

const closeLoginModal = () => {
  isLoginModalOpen.value = false;
};

const showSignupModal = () => {
  isLoginModalOpen.value = false;
  isSignupModalOpen.value = true;
};

const closeSignupModal = () => {
  isSignupModalOpen.value = false;
};

const upgradeClick = async() => {
  upgradeAlert(userStore.uid);
}
console.log(upgradeClick) // just so it gets used so fuck ESLINT

const logoClicked = () => {
  router.push('/')
}


const isMobile = checkMobile();

      // const openCustomerPortal = () => {
        //   isCustomerPortalOpen.value = !isCustomerPortalOpen.value;
        // };
        
        // Components declaration
        // const components = {
          //   LoginPage,
          //   SignupPage,
          //   CustomerPortal,
          // };
          
</script>

<style scoped lang="postcss">
  .link {
    @apply inline-block px-3 py-2;
  }
</style>