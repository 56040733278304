<template>
  <div class="relative">
    <div class="absolute left-4 transform -translate-y-1/2 flex flex-col space-y-1 p-1 bg-gray-800 rounded-lg shadow-lg">
      <button
        v-for="(button, index) in buttons"
        :key="index"
        :class="[
          'flex items-center space-x-1 px-2 py-1 rounded-full w-8 h-8',
          button.name === 'Edit' && props.isEditMode ? 'bg-blue-600 text-white' : (button.highlighted ? 'bg-blue-600 text-white' : 'bg-gray-700 text-gray-300 hover:bg-gray-600'),
          !isLoggedIn && button.requiresLogin ? 'cursor-not-allowed opacity-50' : ''
        ]"
        :disabled="!isLoggedIn && button.requiresLogin"
        @click="button.click"
      >
        <component :is="button.icon" class="h-6 w-6" :class="{ 'bookmark-pop': button.name === 'Follow' && popAnimation }" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { PencilSquareIcon, BookmarkIcon, BookOpenIcon } from '@heroicons/vue/20/solid';
import { useUserStore } from '@/stores/currentUser'; 
import { saveTimelinePost, removeTimelinePost, checkIfHighlighted } from "@/services/conflict-tab/saveTimelinePost.js";
import { useRouter } from 'vue-router';

const props = defineProps({
  timelineIndex: Number,
  conflictName: String,
  openEditPanel: Function,
  isEditMode: Boolean,
  headline: String,
});

const userStore = useUserStore(); 
const isLoggedIn = computed(() => !!userStore.uid);

const isHighlighted = ref(false);
const popAnimation = ref(false);

const handleFollowClick = async () => {
  if (!isLoggedIn.value) return; // Prevent action if the user is not logged in

  // Trigger the pop animation
  popAnimation.value = true;
  setTimeout(() => {
    popAnimation.value = false;
  }, 300); // Animation duration

  if (isHighlighted.value) {
    await removeTimelinePost(props.timelineIndex, props.conflictName);
  } else {
    await saveTimelinePost(props.timelineIndex, props.conflictName);
  }
  isHighlighted.value = !isHighlighted.value;  // Toggle the highlighted state
};

const router = useRouter();

const handleSourceClick = () => {
  const formattedId = `${props.conflictName.replace(/\s+/g, '')}_${props.timelineIndex}`;
  
  // Navigate to the Sources page with the hash
  router.push({ 
    path: '/sources',
    hash: `#${formattedId}` 
  });

  // Wait for a short time to ensure the page has loaded
  setTimeout(() => {
    const element = document.getElementById(formattedId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with ID ${formattedId} not found.`);
    }
  }, 1000); // Adjust the timeout duration as needed
};



const buttons = ref([
  {
    name: 'Edit',
    icon: PencilSquareIcon,
    requiresLogin: true,
    click: props.openEditPanel,
  },
  {
    name: 'Source',
    icon: BookOpenIcon,
    requiresLogin: false,
    click: handleSourceClick,
  },
  {
    name: 'Follow',
    icon: BookmarkIcon,
    click: handleFollowClick,
    highlighted: isHighlighted,
    requiresLogin: true,
  }
]);

onMounted(async () => {
  if (isLoggedIn.value) {
    isHighlighted.value = await checkIfHighlighted(props.timelineIndex, props.conflictName);
  }
});
</script>

<style scoped>
/* Transition for fading in and out */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

/* Menu positioning */
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 100%; /* Position below the button */
  left: 50%;
  transform: translateX(-50%);
}
.mt-2 {
  margin-top: 0.5rem; /* Space between button and menu */
}
.scale-95 {
  transform: scale(0.95);
}
.opacity-0 {
  opacity: 0;
}
.transition-all {
  transition: all 0.3s ease;
}

/* Bookmark Pop Animation */
.bookmark-pop {
  animation: pop 0.3s ease;
}

@keyframes pop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
</style>
