import L from "leaflet";
import 'leaflet-arc';
import '@/assets/mapanimations.css';

import { toRaw } from 'vue'
export function emitArc(map, startLat, startLng, endLat, endLng) {
    const arcLength = 10; // measured by points in polyline
    const steps = 80; // total points in polyline, changes resolution

    var arc = L.Polyline.Arc([startLat, startLng], [endLat, endLng], { // arc that will manipulated and displayed on screen
        color: 'red',
        vertices: steps
    }).addTo(toRaw(map));

    const arcArr = arc.getLatLngs();

    var arcTail = 0;
    var arcHead = arcTail + arcLength; // both arcHead and arcTail are indices of where in arcArr to slice. you can simplify this via: arcHead = arcLength

    function animateArc() {

        if (arcHead <= steps) {
            arc.setLatLngs(arcArr.slice(arcTail, arcHead));
            requestAnimationFrame(animateArc);
        } else if (arcTail <= steps) {
            arc.setLatLngs(arcArr.slice(arcTail, steps));
            requestAnimationFrame(animateArc);
        } else { // animation finished:
            var myIcon = L.divIcon({
                className: 'location-pin',
                html: '<div class="circle"><span></span><span></span><span></span></div>',
                iconSize: [30, 30],
            });

            L.marker([endLat, endLng], {
                icon: myIcon
            }).addTo(toRaw(map));
        }
        arcHead++;
        arcTail++;
    }
    animateArc();
}

// Function to find and apply the 3D pop-out effect to the specific layer
export function apply3DEffect(layerName, context) {
    context.selectedConflictKMLLayer.eachLayer((layer) => {
        console.log(layer.feature.properties.name, layerName, layer.feature.properties.name === layerName)
        if (layer.feature.properties.name === layerName) {
            console.log("layer" + layerName)
            var element = layer._icon || layer._path;
            if (element) {
                // Apply the 3D effect
                element.style.boxShadow = "0 0px 4px rgba(0, 0, 0, 0.3)";
                element.style.zIndex = 1000;

                // Remove the 3D effect after a delay
                setTimeout(() => {
                    element.style.transform = "";
                    element.style.boxShadow = "";
                    element.style.zIndex = "";
                }, 1000);

            }
        }
    });
}