<template>
    <div class="modal">
        <div class="bg-blue-900 dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md w-full relative">
            
            <button @click="close" class="absolute top-0 right-0 mt-4 mr-4 cursor-pointer">
                <svg data-slot="icon" class="h-6 w-6 text-gray-500" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                </svg>
            </button>

            <h1 class="modal-header">Create an Account</h1>

            <div class="mb-6">
                <input type="text" placeholder="Email" v-model="email" class="modal-input">
                <input type="password" placeholder="Password" v-model="password" class="modal-input">
            </div>

            <div class="mb-6 flex flex-col gap-3">
                <button @click="register" class="modal-submit-btn">Submit</button>
                <button @click="signUpWithGoogle" class="modal-google-btn">Signup with Google</button>
            </div>

            <div class="flex items-center justify-center">
                <p class="text-white dark:text-gray-400">Already Have An Account?</p>
                <button @click="switchToLogin" class="ml-2 text-blue-500 hover:underline focus:outline-none">Login</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "SignupPage",
};
</script>
<script setup>
import { ref } from "vue";
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useRouter } from "vue-router";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";

const props = defineProps({
    isOpen: {
        type: Boolean,
    },
});

const emit = defineEmits(['close', 'showLoginModal']);

const email = ref("");
const password = ref("");
const router = useRouter();
const db = getFirestore();

const expertRegions = [
    { "Region": "Mesoamerica", "Score": 0, "Verified": false },
    { "Region": "South America", "Score": 0, "Verified": false },
    { "Region": "Eastern Europe", "Score": 0, "Verified": false },
    { "Region": "North Africa", "Score": 0, "Verified": false },
    { "Region": "Sahel", "Score": 0, "Verified": false },
    { "Region": "Central Africa", "Score": 0, "Verified": false },
    { "Region": "Eastern Africa", "Score": 0, "Verified": false },
    { "Region": "Central Asia", "Score": 0, "Verified": false },
    { "Region": "South Asia", "Score": 0, "Verified": false },
    { "Region": "Middle East", "Score": 0, "Verified": false },
    { "Region": "East Asia", "Score": 0, "Verified": false },
    { "Region": "North America", "Score": 0, "Verified": false },
    { "Region": "Western Europe", "Score": 0, "Verified": false }
];
const subscribedConflicts = [];

const register = () => {
    createUserWithEmailAndPassword(getAuth(), email.value, password.value)
        .then((data) => {
            console.log("Successfully Registered!");

            // Call createUserDocument function here
            createUserDocument(data.user, expertRegions, subscribedConflicts)
                .then(() => router.push("/feed"))
                .catch(error => console.error("Error creating user document", error));
        })
        .catch((error) => {
            console.log(error.code);
            alert(error.message);
        });
};

const signUpWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(getAuth(), provider)
        .then((result) => {
            console.log(result.user);

            // Call createUserDocument function here
            createUserDocument(result.user, expertRegions, subscribedConflicts)
                .then(() => router.push("/feed"))
                .catch(error => console.error("Error creating user document", error));
        })
        .catch((error) => {
            console.log(error);
        });
};

// Create a new user document in Firestore
const createUserDocument = async (user, expertRegions, subscribedConflicts) => {
    const userRef = doc(db, 'users', user.uid);
    const snapshot = await getDoc(userRef);

    if (!snapshot.exists()) {
        const { email, displayName } = user;
        try {
            await setDoc(userRef, {
                displayName,
                email,
                expertRegions,
                subscribedConflicts
            });
        } catch (error) {
            console.error("Error creating user document", error);
        }
    }
    return getUserDocument(user.uid);
};

const getUserDocument = async (uid) => {
    if (!uid) return null;
    try {
        const userDocument = await getDoc(doc(db, 'users', uid));
        return { uid, ...userDocument.data() };
    } catch (error) {
        console.error("Error fetching user", error);
    }
};

const close = () => {
    console.log(props.isOpen);
    emit('close');
};

const switchToLogin = () => {
    emit('showLoginModal');
};

</script>