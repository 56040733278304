// KML --> GeoJSON
import { kml } from "@tmcw/togeojson";
import L from "leaflet";
import { toRaw } from "vue";

// Bounding Box
// import { calculateBoundingBox } from "@/services/map/calculateBoundingBox";
import { getConflictUpdates } from "@/services/map/updateSetup.js";

// **MOST IMPORTANT METHOD** //
// Method Added For Each Conflict Zone Layer On Click
export function addGeoJSONClick(geoJSON, conflict, context) {

    // Iterate Through Each Layer
    geoJSON.eachLayer((layer) => {
        layer.on({
            click: async () => {

                // Adjust KML Layers (KML --> The Special Specific Colorful Versions of Each Map)
                if (context.selectedConflictKMLLayer) { context.selectedConflictKMLLayer.removeFrom(context.map) }
                if (context.selectedConflictHiddenLayer) { context.selectedConflictHiddenLayer._path.style.display = "" }

                // Adjust Various Data Values 
                context.worldMapView = false; // move to Select Mode on Mobile
                context.conflictSelected = true;
                context.showConflictsTab = true;
                context.isNewsfeedTabVisible = false

                // Adjust All Data Values Accordingly
                context.selectedConflictSidesInvolved = conflict.sidesArray;
                context.selectedConflict = conflict.ConflictName;
                context.selectedConflictHistory =
                    conflict.HistoryOfConflict;
                context.BackgroundTimeline = conflict.ConflictTimeline;
                context.selectedConflictUpdates =
                    conflict.Updates;
                context.selectedConflictRegion = conflict.RegionOfConflict;
                if (context.selectedConflictUpdates[0]) {
                    context.selectedConflictLatestUpdate =
                        conflict.Updates[0].update;
                }
                else {
                    context.selectedConflictLatestUpdate = "";
                }

                // Adjust Spacing on Timeline Container and Coalition Container for Different Resolutions
                // checkCutOff(context);
                // adjustTimelineHeight(context);

                // Special Case For Mobile
                if (context.isMobile) { context.selectedMobileConflict = conflict }

                // Accessing KML Map For Conflict IF Exists
                const response = await fetch("maps/kml/" + context.selectedConflict + ".kml");
                if (response.ok) {

                    // Using KML --> GeoJSON Converter
                    const text = await response.text();
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(text, "text/xml");
                    const geojson = kml(doc);

                    // Adding Special Styles 
                    context.selectedConflictKMLLayer = L.geoJSON(geojson, {
                        style: function (feature) {
                            return {
                                fillColor: feature.properties.fill,
                                fillOpacity: feature.properties['fill-opacity'],
                                color: feature.properties.stroke,
                                opacity: feature.properties['stroke-opacity'],
                                weight: 1
                            };
                        },
                        onEachFeature: function (feature, layer) {
                            // Check if the feature is a point
                            if (feature.geometry.type !== "Point") {
                                // Check if the feature has a property named 'name'
                                if (feature.properties && feature.properties.name) {
                                    // Bind a tooltip to the layer with the feature's name
                                    layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto", className: 'kml-map-tooltip' });
                                }
                            }
                        },
                        filter: function (feature) {
                            // Filter out point features
                            return feature.geometry.type !== "Point";
                        }
                    }).addTo(toRaw(context.map));

                    // Save The Hidden Conflict Zone Layer So It Can Return When User Clicks Off KML
                    context.selectedConflictHiddenLayer = layer
                    layer._path.style.display = "none";
                }
                
                getConflictUpdates(conflict, context, layer)
            }
        });
    });
}

export async function selectedMobileConflictAdjustments(newValue, context) {
    // Async Bullshit for Getting KML Map
    let response = ""
    let text = ""
    if (newValue !== null) {
        response = await fetch("maps/kml/" + newValue.ConflictName + ".kml");
        if (response.ok) {
            text = await response.text();
        }
    }

    // Next Tick Because Of HTML/CSS concerns
    context.$nextTick(() => {

        // Case If the New Value is a conflict (not clicking off/selecting new one)
        if (newValue !== null && newValue !== '') {

            // Setting New Data Values
            context.selectedConflict = newValue.ConflictName;
            context.conflictSelected = true;

            // Accessing Map
            // const conflictZone = newValue.ConflictZoneMap.toString().replace(/\\n/g, "\\n").replace(/\\'/g, "\\'").replace(/\\"/g, '\\"').replace(/\\&/g, "\\&").replace(/\\r/g, "\\r").replace(/\\t/g, "\\t").replace(/\\b/g, "\\b").replace(/\\f/g, "\\f"); // Remove non-printable and other non-valid JSON characters s = s.replace(/[\u0000-\u0019]+/g,"");
            // let parsedData = JSON.parse(conflictZone);


            // let coordinates = parsedData.features.map(feature => feature.geometry.coordinates);

            // // Calculate the bounding box
            // let boundingBox = calculateBoundingBox(coordinates.flat(2));

            // // Deconstruct the bounding box into its components
            // let [minLongitude, minLatitude, maxLongitude, maxLatitude] = boundingBox;

            // // Create a Leaflet bounds object using the southwest and northeast corners
            // let bounds = L.latLngBounds(
            //     L.latLng(minLatitude, minLongitude), // Southwest corner
            //     L.latLng(maxLatitude, maxLongitude)  // Northeast corner
            // );

            // // Because Map Size Changes From World Map View To Conflict Selected View, Must Invalidate
            // // ** CAN CAUSE PROBLEMS **
            // context.map.invalidateSize({ animate: true, pan: false });

            // // Use the bounds with the Leaflet map to set the view
            // context.map.fitBounds(bounds, { padding: [25, 25] });


            // Adjusting Data Values
            context.selectedConflictSidesInvolved = newValue.sidesArray;
            context.selectedConflict = newValue.ConflictName;
            context.selectedConflictHistory =
                newValue.HistoryOfConflict;
            context.BackgroundTimeline = newValue.ConflictTimeline;
            context.selectedConflictUpdates =
                newValue.Updates;
            context.selectedConflictRegion = newValue.RegionOfConflict;
            if (context.selectedConflictUpdates[0]) {
                context.selectedConflictLatestUpdate =
                    newValue.Updates[0].update;
            }

            // Saving Hidden Conflict zone Layer
            let chosenLayer = null

            // Removing All Layers Not Selected
            context.map.eachLayer((layer) => {
                if (layer.feature && layer.feature.properties && layer._path) {
                    if (layer.feature.properties.ConflictName == context.selectedConflict) {
                        layer._path.style.display = "";
                        chosenLayer = layer;
                    }
                    else {
                        layer._path.style.display = "none";
                    }
                }
            });

            // Removing all Markers Not Selected
            context.markers.forEach((marker) => {
                const conflict = marker.options.properties;
                if (conflict.ConflictName == context.selectedConflict) {
                    if(marker._path) { 
                        marker._path.style.display = "";
                        if (marker._shadow) {
                            marker._shadow.style.display = "";
                        }
                    }
                }
                else {
                    // marker._path.style.display = "none";
                    // if (marker._shadow) {
                    //     marker._shadow.style.display = "";
                    // }
                }
            });

            // Accessing KML using Async Values On top of Function
            if (response.ok) {

                // KML --> GeoJSON converter
                const parser = new DOMParser();
                const doc = parser.parseFromString(text, "text/xml");
                const geojson = kml(doc);

                // KML Setup
                context.selectedConflictKMLLayer = L.geoJSON(geojson, {
                    style: function (feature) {
                        return {
                            fillColor: feature.properties.fill,
                            fillOpacity: feature.properties['fill-opacity'],
                            color: feature.properties.stroke,
                            opacity: feature.properties['stroke-opacity'],
                            weight: 1
                        };
                    },
                    onEachFeature: function (feature, layer) {
                        // Check if the feature is a point
                        if (feature.geometry.type !== "Point") {
                            // Check if the feature has a property named 'name'
                            if (feature.properties && feature.properties.name) {
                                // Bind a tooltip to the layer with the feature's name
                                layer.bindTooltip(feature.properties.name, { permanent: false, direction: "auto", className: 'kml-map-tooltip' });
                            }
                        }
                    },
                    filter: function (feature) {
                        // Filter out point features
                        return feature.geometry.type !== "Point";
                    }
                })

                context.selectedConflictKMLLayer.addTo(toRaw(context.map));
                // Apply the fitBounds with adjusted padding
                context.map.fitBounds(context.selectedConflictKMLLayer.getBounds(), {
                    paddingTopLeft: [0, -context.map.getSize().y * 0.2],
                    paddingBottomRight: [0, context.map.getSize().y * 0.2]
                });

                context.selectedConflictHiddenLayer = chosenLayer;
                if (chosenLayer) {
                    chosenLayer._path.style.display = "none";
                }
            }

        }

        // Case For User Clicking Off/Selecting New Conflict
        else if (newValue == null) {

            // Because Map Size Changes From Conflict Selected To World Map View, Must Invalidate
            // ** CAN CAUSE PROBLEMS ** //
            context.map.invalidateSize();

            // Setting New View Around Map
            context.map.setView([40, 10], 2);

            // Readding Back Each Conflict zone
            context.map.eachLayer((layer) => {
                if (layer._path) {
                    layer._path.style.display = "";
                }
            });

            // Readding Back Each Marker
            context.markers.forEach((marker) => {
                if (marker._path) {
                    marker._path.style.display = "";
                    if (marker._shadow) {
                        marker._shadow.style.display = "";
                    }
                }
            });

            // World Map View Back
            let mapContainer = document.getElementById('capture')
            mapContainer.style.height = 100 + 'vh';

        }

        // This Exists For Some Reason lol
        else {
            context.conflictSelected = false;
        }
    })
}

// Method to Close Conflicts Tab, Reset All Conflict Values For Data
export function closeConflictsTab(context) {
    context.showConflictsTab = false;
    context.conflictSelected = false;

    context.selectedMobileConflict = null;
    context.selectedConflict = "Click On Conflict Zone To Get Started!";
    context.selectedConflictType = "";
    context.selectedConflictRegion = "";
    context.selectedConflictCountries = "";
    context.selectedConflictSidesInvolved = "";
    context.selectedConflictBackground = "";
    context.selectedConflictHistory = "";
    context.selectedConflictLatestUpdate = "";
    context.selectedConflictUpdates = [];
    context.BackgroundTimeline = [];
    if (context.selectedConflictKMLLayer) { context.selectedConflictKMLLayer.removeFrom(context.map) }
    if (context.selectedConflictHiddenLayer) { context.selectedConflictHiddenLayer._path.style.display = "" }
    context.markers.forEach(marker => {
        const conflict = marker.options.properties;
        if (marker.options.customOptions && marker.options.customOptions.flash || conflict.ConflictName === "Global") {
            marker._icon.style.display = "";
            if (marker._shadow) {
                marker._shadow.style.display = "";
            }
        } else {
            marker._icon.style.display = "none";
            if (marker._shadow) {
                marker._shadow.style.display = "none";
            }
        }
    });

    context.map.flyTo([40, 10], 3)
}

// Method to Close Conflicts Tab, Reset All Conflict Values For Mobile, no difference but keeping just in case
export function closeMobileConflictsTab(context) {
    context.showConflictsTab = false;
    context.conflictSelected = false;

    context.selectedMobileConflict = null;
    context.selectedConflict = "Click On Conflict Zone To Get Started!";
    context.selectedConflictType = "";
    context.selectedConflictRegion = "";
    context.selectedConflictCountries = "";
    context.selectedConflictSidesInvolved = "";
    context.selectedConflictBackground = "";
    context.selectedConflictHistory = "";
    context.selectedConflictLatestUpdate = "";
    context.selectedConflictUpdates = [];
    context.BackgroundTimeline = [];
    
    if (context.selectedConflictKMLLayer) { context.selectedConflictKMLLayer.removeFrom(context.map) }
    if (context.selectedConflictHiddenLayer) { context.selectedConflictHiddenLayer._path.style.display = "" }
}



// Method for Fixing Timeline Height
// function adjustTimelineHeight(context) {
//     context.$nextTick(() => {
//         const timeline = context.$refs.timeline;
//         const coalitionContainerHeight = context.$refs.coalitionContainer.offsetHeight;
//         const screenRelativeHeight = (coalitionContainerHeight / (window.innerHeight * 0.9)) * 100;

//         // Calculate new height
//         let newHeight = `calc(64vh - ${screenRelativeHeight}vh)`;

//         if (context.isCutOff) {
//             let calcHeight = 64-screenRelativeHeight-3;
//             newHeight = `${calcHeight}vh`
//         }

//         timeline.style.height = newHeight;
//     });
// }

// // Method for Checking Cut Off For Conflict Buttons
// function checkCutOff(context) {
//     context.$nextTick(() => {
//         const container = context.$refs.conflictstab;
//         context.isCutOff = isOverflown(container);
//     });
// }

// function isOverflown(element) {
//     return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
// }
