<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-900 dark:text-gray-100">
      <GlobalNavbar />
      <div class="min-h-screen p-8">
        <h1 class="text-4xl font-bold mb-8 mt-20 text-center">Our Recommended Aid Organizations</h1>
        <div v-if="isLoading" class="text-center">Loading...</div>
        <div v-else>
          <div class="flex justify-center mb-8">
            <select v-model="selectedConflict" class="p-3 text-lg bg-gray-200 dark:bg-gray-800 dark:text-gray-100 rounded-lg shadow-lg focus:outline-none focus:ring-2 focus:ring-blue-500">
              <option disabled value="">Please Select Conflict</option>
              <option value="">Show All Conflicts</option>
              <option v-for="conflictName in Object.keys(conflictsDictionary)" :key="conflictName" :value="conflictName">
                {{ conflictName }}
              </option>
            </select>
          </div>
          <div class="organizations-feed">
            <div v-if="selectedConflict !== ''">
                <h3 class="text-2xl font-semibold mb-4 text-center">{{ selectedConflict }}</h3>
                <div class="flex flex-wrap justify-center">
                    <div v-for="organization in conflictsDictionary[selectedConflict]" :key="organization.Name" class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-6 px-2">
                    <a :href="organization.Link" target="_blank" class="relative block group overflow-hidden">
                        <img :src="organization.Image" alt="Image not loaded" class="w-full h-64 object-cover rounded-md transition-transform duration-300 filter blur-0 cursor-pointer group-hover:filter-none group-hover:scale-105" />
                        <div class="absolute bottom-0 left-0 w-full bg-black bg-opacity-75 text-white text-center py-2 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        {{ organization.Name }}
                        </div>
                    </a>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="(organizations, conflictName) in conflictsDictionary" :key="conflictName" class="mb-8">
                    <h3 class="text-2xl font-semibold mb-4 text-center">{{ conflictName }}</h3>
                    <div class="flex flex-wrap justify-center">
                    <div v-for="organization in organizations" :key="organization.Name" class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/5 mb-6 px-2">
                        <a :href="organization.Link" target="_blank" class="relative block group overflow-hidden">
                        <img :src="organization.Image" alt="Image not loaded" class="w-full h-64 object-cover rounded-md transition-transform duration-300 filter blur-0 cursor-pointer group-hover:filter-none group-hover:scale-105" />
                        <div class="absolute bottom-0 left-0 w-full bg-black bg-opacity-75 text-white text-center py-2 px-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                            {{ organization.Name }}
                        </div>
                        </a>
                    </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  name: "OrganizationsTable",
};
</script>
<script setup>
import { ref, reactive, onMounted } from "vue";
import GlobalNavbar from "@/components/Navbar.vue"; 
import { useRoute } from 'vue-router'; // Import to access route query

const AWS = require("aws-sdk");

const conflictsDictionary = reactive({});
const selectedConflict = ref("");
const isLoading = ref(true);
const route = useRoute(); // Get the current route to access the query

const fetchAllConflicts = async (docClient, table_name) => {
  let params = { TableName: table_name };
  let allConflicts = [];
  let data;

  do {
    data = await docClient.scan(params).promise();
    allConflicts.push(...data.Items);
    params.ExclusiveStartKey = data.LastEvaluatedKey;
  } while (typeof data.LastEvaluatedKey !== "undefined");

  allConflicts.sort((a, b) => {
    if (a.ConflictName === "Israel-Palestine Conflict") return -1;
    if (b.ConflictName === "Israel-Palestine Conflict") return 1;
    return 0;
  });

  return allConflicts;
};

onMounted(() => {
  const docClient = new AWS.DynamoDB.DocumentClient();
  const table_name = "TLDRMapConflicts";

  fetchAllConflicts(docClient, table_name)
    .then(conflicts => {
      conflicts.forEach(conflict => {
        if (conflict.Organizations) {
          conflictsDictionary[conflict.ConflictName] = conflict.Organizations;
        }
      });

      // Set selectedConflict from query if exists
      const queryConflict = route.query.selectedConflict;
      if (queryConflict && conflictsDictionary[queryConflict]) {
        selectedConflict.value = queryConflict;
      }

      isLoading.value = false;
    })
    .catch(err => {
      console.error("Error fetching data:", err);
    });
});
</script>

  

<style scoped>
.organization-entry {
    flex: 0 1 calc(33.33% - 10px);
    margin-right: 10px;
    margin-top: 1rem;
    box-sizing: border-box;
    transition: transform 0.3s;
}

.blurred-image {
    width: 100%;
    height: 100%;
    filter: blur(0);
    cursor: pointer;
    transition: filter 0.3s, transform 0.3s;
    border-radius: 5px;
}
</style>