<template>
  <div class="relative mb-2 w-11/12 bg-gray-800 object-contain border-l-4 border-blue-500 p-4 rounded-lg shadow-lg group">
    <div class="relative pl-12 h-full" :class="allowReactions ? 'min-h-32' : 'min-h-28'">
      <div class="absolute left-4 transform -translate-y-1/2">
        <UpdateButton 
          v-if="allowReactions" 
          :source = "event.SourceOfUpdate"
          :timelineIndex="timelineIndex" 
          :conflictName="conflictName" 
          :eventsLength="eventsLength"
        />
        <EditButton 
          v-if="!allowReactions" 
          :timelineIndex="timelineIndex" 
          :conflictName="conflictName" 
          :openEditPanel="openEditPanel" 
          :isEditMode="isEditMode" 
          :headline="event.EventHeadline"
        />
      </div>
      <div>
        <h4 class="text-white font-semibold text-lg md:text-lg lg:text-lg">{{ event.EventHeadline }}</h4>
        <p class="mt-1 text-sm text-gray-400">{{ computeTime ? timeSince(new Date(event.EventDate))[0] : event.EventDate }}</p>
      </div>
      <div v-if="reactionArray && allowReactions" class="flex space-x-4 mt-4">
        <div v-for="(count, emoji) in displayedReactions" :key="emoji" class="flex items-center space-x-2">
          <span>{{ emoji }}</span>
          <span class="text-gray-300">{{ count }}</span>
        </div>
      </div>
    </div>

    <!-- Original description -->
    <Disclosure as="div" class="relative">
      <template v-slot="{ open }">
        <DisclosureButton class="absolute bottom-1 right-1 text-white focus:outline-none z-10">
          <ChevronUpIcon
            :class="{ 'rotate-180': open }"
            class="h-5 w-5 text-gray-300 transition-transform duration-300"
          />
        </DisclosureButton>
        <DisclosurePanel 
          as="div" 
          class="mt-4 p-4 border border-gray-700 rounded-lg bg-gray-900 shadow-inner transition-opacity duration-300"
          :class="{ 'opacity-100': open, 'opacity-0': !open }">
          <p class="text-sm text-gray-300">{{ event.EventDescription }}</p>
        </DisclosurePanel>
      </template>
    </Disclosure>

    <!-- Edit Panel, shown only if edit mode is on -->
    <Disclosure as="div" class="relative" v-if="isEditMode">
      <template v-slot="{ open }">
        <DisclosureButton class="absolute bottom-1 right-1 text-white focus:outline-none z-10">
          <ChevronUpIcon
            :class="{ 'rotate-180': open }"
            class="h-5 w-5 text-gray-300 transition-transform duration-300"
          />
        </DisclosureButton>
        <DisclosurePanel 
          as="div" 
          class="mt-4 p-4 border border-gray-700 rounded-lg bg-gray-900 shadow-inner transition-opacity duration-300 h-[20%]"
          :class="{ 'opacity-100': open, 'opacity-0': !open }"
        >
          <textarea v-model="editedDescription" class="w-full bg-gray-800 text-white border border-gray-700 p-2 rounded-lg" />
          <button @click="saveEditedDescription" class="mt-2 bg-blue-600 text-white py-1 px-4 rounded">Save</button>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronUpIcon } from '@heroicons/vue/20/solid';
import EditButton from './EditButton.vue';
import UpdateButton from './UpdateButton.vue';
import { timeSince } from "@/services/computation/calcTimeSince.js";
import { fetchReactions } from '@/services/conflict-tab/reactionLogic.js';
import addEditedEvent from "@/services/conflict-tab/saveEditDescription";  
import { useUserStore } from '@/stores/currentUser'; 

const props = defineProps({
  event: Object,
  urls: Array,
  reactionCounts: Object,
  allowReactions: Boolean,
  computeTime: Boolean,
  conflictName: String,
  timelineIndex: Number,
  eventsLength: Number,
});

const userStore = useUserStore();
const currentUser = computed(() => userStore.uid); 

const editedDescription = ref(props.event.EventDescription);
const reactionArray = ref({});
const isEditMode = ref(false); 

const emojis = {
  heart: '❤️',
  pray: '🙏',
  cry: '😢',
  cap: '🧢'
};

async function fetchReactionData() {
  reactionArray.value = await fetchReactions(props.conflictName, props.timelineIndex);
}

onMounted(() => {
  fetchReactionData();
});

const displayedReactions = computed(() => {
  const reactions = {};
  Object.keys(reactionArray.value).forEach((key) => {
    if (emojis[key]) {
      reactions[emojis[key]] = reactionArray.value[key];
    }
  });
  return reactions;
});

const saveEditedDescription = () => {
  console.log('Saving description:', editedDescription.value);
  
  // Call addEditedEvent to save the edited description to the database
  addEditedEvent(
    props.timelineIndex,
    props.conflictName,
    props.event.EventHeadline,
    props.event.EventDate,
    props.event.EventDescription,
    editedDescription.value,
    currentUser.value
  );
};

const openEditPanel = () => {
  isEditMode.value = !isEditMode.value;
};
</script>
