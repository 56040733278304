import { toRaw } from 'vue'
import L from "leaflet";
import 'leaflet-polylinedecorator';
import "leaflet.geodesic";

export function applyProxyFilter(context, int) {
    context.selectedRegion = 'all';
    context.selectedTypeOfConflict = 'none'
    context.selectedResource = 'none'
    context.selectedCombatType = 'none'
    context.circleGroup.clearLayers();

    if (int == 0) {
        context.selectedCountryCode = "xx"
        context.selectedCountryName = "None Selected"
    }
    else {
        context.selectedProxy = "none"
        context.decoratorLayers.forEach(layerObj => {
            context.map.removeLayer(layerObj.polyline);
            context.map.removeLayer(layerObj.decorator);
            context.map.removeLayer(layerObj.fromMarker);
            context.map.removeLayer(layerObj.toMarker);
        });
    }

    context.markers.forEach(marker => {
        if (marker.options.customOptions && marker.options.customOptions.flash) {
            marker._icon.style.display = "none";
            if (marker._shadow) {
                marker._shadow.style.display = "none";
            }
        }
    });
    if (context.map) {
        context.map.eachLayer((layer) => {
            if (layer.feature && layer.feature.properties) {
                layer._path.style.display = "";
                if (context.selectedProxy === layer.feature.properties.ConflictName) {
                    context.decoratorLayers.forEach(layerObj => {
                        context.map.removeLayer(layerObj.polyline);
                        context.map.removeLayer(layerObj.decorator);
                        context.map.removeLayer(layerObj.fromMarker)
                        context.map.removeLayer(layerObj.toMarker)
                    });
                    layer._path.style.display = "";
                    layer.feature.properties.Proxies.forEach(proxy => {
                        const latlngs = [
                            [proxy.FromCoordinates.Lat, proxy.FromCoordinates.Lng],
                            [proxy.ToCoordinates.Lat, proxy.ToCoordinates.Lng]
                        ];

                        const FromCoordsLeaflet = new L.LatLng(proxy.FromCoordinates.Lat, proxy.FromCoordinates.Lng);
                        const ToCoordsLeaflet = new L.LatLng(proxy.ToCoordinates.Lat, proxy.ToCoordinates.Lng);
                        const polyline = new L.Geodesic([FromCoordsLeaflet, ToCoordsLeaflet])


                        let decorator = L.polylineDecorator(polyline, {
                            patterns: [{
                                offset: '50%',
                                repeat: 0,
                                symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { stroke: true } })
                            }]
                        })


                        const fromMarker = L.circleMarker(latlngs[0], {
                            color: 'red',
                            radius: 5,
                        }).addTo(toRaw(context.map));

                        const toMarker = L.circleMarker(latlngs[1], {
                            color: 'red',
                            radius: 1,
                        }).addTo(toRaw(context.map));

                        if (proxy.ProxyTier == "Major") {
                            polyline.addTo(toRaw(context.map));
                            decorator.addTo(toRaw(context.map));
                        }
                        else {
                            fromMarker.on('mouseover', () => {
                                polyline.addTo(toRaw(context.map));
                                decorator.addTo(toRaw(context.map));
                            });

                            fromMarker.on('mouseout', () => {
                                polyline.removeFrom(context.map);
                                decorator.removeFrom(context.map);
                            });
                        }

                        fromMarker.on('mouseover', () => {
                            polyline.setStyle({ weight: 5 });
                            polyline.setStyle({ color: '#FFD700' });
                            toMarker.setStyle({ radius: 8 })
                        });
                        fromMarker.on('mouseout', () => {
                            polyline.setStyle({ weight: 1 });
                            polyline.setStyle({ color: '#3388ff' });
                            toMarker.setStyle({ radius: 1 })
                        });


                        context.decoratorLayers.push({
                            polyline: polyline,
                            decorator: decorator,
                            fromMarker: fromMarker,
                            toMarker: toMarker,
                        });

                        const popupContent = `
                                <div class="global-news-item">
                                    <div class="global-news-title">${proxy.ProxyName}</div>
                                    <div class="global-news-content">
                                        <div class="global-news-text">
                                            <strong>Information:</strong> ${proxy.ProxyDescription}<br>
                                            <strong>Duration:</strong> ${proxy.ProxyDuration}<br>
                                            <strong>Financial Aid:</strong> $${proxy.ProxyFinancialAid}<br>
                                        </div>
                                    </div>
                                </div>`;
                        decorator.bindTooltip(popupContent);
                        // polyline.bindTooltip(popupContent);
                        fromMarker.bindTooltip(popupContent);
                        toMarker.bindTooltip(popupContent, {
                            permanent: false,
                            sticky: true,
                            className: 'custom-tooltip'
                        });

                    });
                }
                else if (context.selectedCountryName !== "None Selected" && layer.feature.properties.Proxies) {
                    const hasMatchingProxyPower = layer.feature.properties.Proxies.some(proxy => {
                        return proxy.ProxyPowers === context.selectedCountryName;
                    });

                    if (hasMatchingProxyPower) {
                        layer._path.style.display = "";
                        layer.feature.properties.Proxies.forEach(proxy => {
                            if (proxy.ProxyPowers === context.selectedCountryName) {
                                const latlngs = [
                                    [proxy.FromCoordinates.Lat, proxy.FromCoordinates.Lng],
                                    [proxy.ToCoordinates.Lat, proxy.ToCoordinates.Lng]
                                ];

                                const FromCoordsLeaflet = new L.LatLng(proxy.FromCoordinates.Lat, proxy.FromCoordinates.Lng);
                                const ToCoordsLeaflet = new L.LatLng(proxy.ToCoordinates.Lat, proxy.ToCoordinates.Lng);
                                const polyline = new L.Geodesic([FromCoordsLeaflet, ToCoordsLeaflet]).addTo(toRaw(context.map));

                                const decorator = L.polylineDecorator(polyline, {
                                    patterns: [{
                                        offset: '50%',
                                        repeat: 0,
                                        symbol: L.Symbol.arrowHead({ pixelSize: 15, polygon: false, pathOptions: { stroke: true } })
                                    }]
                                }).addTo(toRaw(context.map));

                                const fromMarker = L.circleMarker(latlngs[0], {
                                    color: 'red',
                                    radius: 3 // You can adjust the size by changing the radius
                                }).addTo(toRaw(context.map));

                                const toMarker = L.circleMarker(latlngs[1], {
                                    color: 'red',
                                    radius: 3 // You can adjust the size by changing the radius
                                }).addTo(toRaw(context.map));

                                toMarker.on('mouseover', () => {
                                    polyline.setStyle({ weight: 5 });
                                    polyline.setStyle({ color: '#FFD700' });
                                });
                                toMarker.on('mouseout', () => {
                                    polyline.setStyle({ weight: 3 });
                                    polyline.setStyle({ color: '#3388ff' });
                                });

                                context.decoratorLayers.push({
                                    polyline: polyline,
                                    decorator: decorator,
                                    fromMarker: fromMarker,
                                    toMarker: toMarker,
                                });

                                const popupContent = `
                                        <div class="global-news-item">
                                            <div class="global-news-title">${proxy.ProxyName}</div>
                                            <div class="global-news-content">
                                                <div class="global-news-text">
                                                    <strong>Information:</strong> ${proxy.ProxyDescription}<br>
                                                    <strong>Duration:</strong> ${proxy.ProxyDuration}<br>
                                                    <strong>Financial Aid:</strong> $${proxy.ProxyFinancialAid}<br>
                                                </div>
                                            </div>
                                        </div>`;
                                decorator.bindTooltip(popupContent);
                                // polyline.bindTooltip(popupContent);
                                fromMarker.bindTooltip(popupContent);
                                toMarker.bindTooltip(popupContent);
                            }
                        });
                    }
                    else {
                        layer._path.style.display = "none";
                    }
                }
                else if (context.selectedProxy === "none" && context.selectedCountryName === "None Selected") {
                    context.decoratorLayers.forEach(layerObj => {
                        context.map.removeLayer(layerObj.polyline);
                        context.map.removeLayer(layerObj.decorator);
                        context.map.removeLayer(layerObj.fromMarker)
                        context.map.removeLayer(layerObj.toMarker)
                    });

                    context.decoratorLayers = [];
                    layer._path.style.display = "";
                    context.markers.forEach(marker => {
                        if (marker.options.customOptions && marker.options.customOptions.flash) {
                            marker._icon.style.display = "";
                            if (marker._shadow) {
                                marker._shadow.style.display = "";
                            }
                        }
                    });
                } else {
                    layer._path.style.display = "none";
                }
            }
        });
    }
}

export function toggleDropdown(context) {
    context.dropdownOpen = !context.dropdownOpen;
}
export function selectCountry(context, country) {
    context.selectedCountryName = country.name;
    context.selectedCountryCode = country.code;
    context.dropdownOpen = false;
    context.applyProxyFilter(1);
}