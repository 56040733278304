export function applyRegionFilter(context) {
    context.selectedCountryCode = "xx"
    context.selectedCountryName = "None Selected"
    context.selectedProxy = "none"

    if (context.selectedResourceMap) { context.selectedResourceMap.removeFrom(context.map) }
    context.decoratorLayers.forEach(layerObj => {
        context.map.removeLayer(layerObj.polyline);
        context.map.removeLayer(layerObj.decorator);
        context.map.removeLayer(layerObj.fromMarker);
        context.map.removeLayer(layerObj.toMarker);
    });

    if (context.map) {
        context.map.eachLayer((layer) => {
            if (layer.feature && layer.feature.properties) {
                const isVisibleByResource = (context.selectedResource === 'none' || (layer.feature.properties.Resources && layer.feature.properties.Resources.includes(context.selectedResource)));
                const isVisibleByTypeOfConflict = (context.selectedTypeOfConflict === 'none' || layer.feature.properties.TypeOfConflict === context.selectedTypeOfConflict);
                const isVisibleByCombatType = (context.selectedCombatType === 'none' || context.selectedCombatType === 'all' || (layer.feature.properties.ConflictName == context.selectedCombatType));
                if ((context.selectedRegion === 'all' || layer.feature.properties.Region === context.selectedRegion) && isVisibleByResource && isVisibleByTypeOfConflict && isVisibleByCombatType) {
                    layer._path.style.display = "";  // Show the layer
                } else {
                    layer._path.style.display = "none";  // Hide the layer
                }
            }
        });
        context.markers.forEach(marker => {
            const conflict = marker.options.properties;
            const isVisibleByResource = (context.selectedResource === 'none' || (conflict.Resources && conflict.Resources.includes(context.selectedResource)));
            const isVisibleByRegion = (context.selectedRegion === 'all' || conflict.RegionOfConflict === context.selectedRegion);
            const isVisibleByTypeOfConflict = (context.selectedTypeOfConflict === 'none' || conflict.TypeOfConflict === context.selectedTypeOfConflict);
            const isVisibleByCombatType = (context.selectedCombatType === 'none' || context.selectedCombatType === 'all' || conflict.ConflictName == context.selectedCombatType);
            if (isVisibleByRegion && isVisibleByResource && isVisibleByCombatType && isVisibleByTypeOfConflict && marker.options.customOptions && marker.options.customOptions.flash) {
                marker._icon.style.display = "";
                if (marker._shadow) {
                    marker._shadow.style.display = "";
                }
            } else {
                marker._icon.style.display = "none";
                if (marker._shadow) {
                    marker._shadow.style.display = "none";
                }
            }
        });

    }
}