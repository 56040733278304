<template>
  <div class="flex flex-wrap justify-center gap-4 p-4">
    <!-- Loop through columns and display flags -->
    <div 
      v-for="(column, index) in columns"
      v-show="index <= 2 && column && column.flags && column.flags.length"
      :key="column.id"
      :id="column.id"
      class="flex flex-col items-center p-2 border border-gray-600 rounded-lg max-w-[75px] bg-gray-800 dark:bg-gray-900"
    >
      <img
        v-for="flag in column.flags"
        :key="flag"
        :src="generateFlagPath(flag)"
        alt="flag"
        class="w-16 h-8 mb-2 border border-gray-400 rounded-md shadow-md hover:shadow-lg transition-shadow"
        v-tippy="{ content: flag, placement: 'bottom' }"
        @error="handleImageError"
      />
    </div>

    <div 
      v-if="columns[3] && columns[3].visible && (!columns[4] || !columns[4].visible)"
      :id="columns[3].id"
      class="flex flex-col items-center p-2 border border-gray-600 rounded-lg max-w-[75px] bg-gray-800 dark:bg-gray-900"
    >
      <img
        v-for="flag in columns[3].flags"
        :key="flag"
        :src="generateFlagPath(flag)"
        alt="flag"
        class="w-16 h-8 mb-2 border border-gray-400 rounded-md shadow-md hover:shadow-lg transition-shadow"
        v-tippy="{ content: flag, placement: 'bottom' }"
        @error="handleImageError"
      />
    </div>

    <div 
      v-if="(columns[3] && columns[3].visible) && (columns[4] && columns[4].visible)"
      id="column4-5-container"
      class="flex flex-col items-center p-2 border border-gray-600 rounded-lg bg-gray-800 dark:bg-gray-900"
    >
      <!-- Column 4 -->
      <div 
        :id="columns[3].id"
        class="flex flex-col items-center p-2 border border-gray-600 rounded-lg max-w-[75px]"
      >
        <img
          v-for="flag in columns[3].flags"
          :key="flag"
          :src="generateFlagPath(flag)"
          alt="flag"
          class="w-16 h-8 mb-2 border border-gray-400 rounded-md shadow-md hover:shadow-lg transition-shadow"
          v-tippy="{ content: flag, placement: 'bottom' }"
          @error="handleImageError"
        />
      </div>

      <!-- Divider -->
      <div
        id="columnDivider"
        class="h-px bg-gray-500 w-full my-2"
      ></div>

      <!-- Column 5 -->
      <div 
        :id="columns[4].id"
        class="flex flex-col items-center p-2 border border-gray-600 rounded-lg max-w-[75px]"
      >
        <img
          v-for="flag in columns[4].flags"
          :key="flag"
          :src="generateFlagPath(flag)"
          alt="flag"
          class="w-16 h-8 mb-2 border border-gray-400 rounded-md shadow-md hover:shadow-lg transition-shadow"
          v-tippy="{ content: flag, placement: 'bottom' }"
          @error="handleImageError"
        />
      </div>
    </div>
  </div>
</template>



<script setup>
import { ref, watch, onMounted } from 'vue';

const columns = ref([
{ id: "column1", flags: [], visible: true },
{ id: "column2", flags: [], visible: true },
{ id: "column3", flags: [], visible: true },
{ id: "column4", flags: [], visible: true },
{ id: "column5", flags: [], visible: true },
]);


const props = defineProps({
insidesArray: {
  type: Array,
},
});

const generateFlagPath = (country) => {
if (!country) return "flags/defaultFlag.svg";
switch (country) {
  case "Communist Party of Burma": return "flags/Communist Party of Burma.png";
  case "Nigerién Coup Government": return "flags/Niger Coup Government.jpg";
  case "Paraguayan People's Army": return "flags/Paraguayan Peoples Army.svg";
  case "Kurdistan Workers' Party": return "flags/Kurdistan Workers Party.svg";
  case "National Socialist Council of Nagaland": return "flags/nagaland.png";
  case "Manipur Militants": return "flags/Manipur.png";
  case "United Liberation Front of Assam": return "flags/United Liberation Front of Assam.png";
  case "New People's Army": return "flags/NPA.png";
  case "Komala Party of Iranian Kurdistan": return "flags/Komala Party.png";
  case "Democratic Party of Iranian Kurdistan": return "flags/Democratic Party of Iranian Kurdistan.png";
  case "Kurdistan Freedom Hawks": return "flags/Kurdistan Freedom Hawks.jpg";
  case "Republic of Korea": return "flags/Republic of Korea.png";
  case "Democratic People’s Republic of Korea": return "flags/Democratic People_s Republic of Korea.svg";
  case "Peoples’ United Revolutionary Movement": return "flags/Peoples United Revolutionary Movement.svg";
  case "Tobruk Government": return "flags/Tobruk Government.png";
  case "Haiti": return "flags/Haiti.png";
  default: return `flags/${country}.svg`;
}
};

const populateImages = (insidesArray) => {
const sidesArray = insidesArray;

for (let i = 0; i < columns.value.length; i++) { 
  if (sidesArray[i]) {
    columns.value[i].flags = sidesArray[i];
    columns.value[i].visible = true;
  } else {
    columns.value[i].visible = false;
  }
}
};

const clearColumnsFlags = () => {
columns.value.forEach(column => {
  column.flags = [];
});
};

const handleImageError = (event) => {
event.target.src = "flags/defaultFlag.svg";
};

watch(() => props.insidesArray, (newValue) => {
clearColumnsFlags();
populateImages(newValue);
}, { immediate: false });

onMounted(() => {
populateImages(props.insidesArray);
});
</script>
