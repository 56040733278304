import { getFirestore, collection, doc, updateDoc, getDoc, getDocs, addDoc, query, where, onSnapshot } from "firebase/firestore";
import Swal from 'sweetalert2'

export async function createSub(uid) {
    const db = getFirestore();
    const collectionRef = collection(
        db,
        "users",
        uid,
        "checkout_sessions"
    );
    
    const products = await fetchProducts();
    let priceSelected = products[0].prices[0].id;

    const docRef = await addDoc(collectionRef, {
        price: priceSelected,
        success_url: window.location.origin,
        cancel_url: window.location.origin,
    });
    onSnapshot(docRef, (snap) => {

        const { error, url } = snap.data();

        if (error) {
            console.error(`An error occured: ${error.message}`);
            Swal.fire({
                icon: 'error',
                title: 'Error with Payment'
            });
        }

        if (url) {
            window.location.assign(url);
        }
    });
}

export async function subscribeAlert(uid) {
    const db = getFirestore();
    if(!uid) {
        return;
    }
    const userRef = await doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    const userData = userSnap.data();
    if (userData.isSubscribed==null) {
        Swal.fire({
            title: 'Choose your plan',
            text: 'Do you want to proceed with the free plan or subscribe to the Pro version?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Subscribe',
            cancelButtonText: 'Proceed with Free Version',
        }).then(async (result) => {
            if (result.isConfirmed) {
                await createSub(uid);
            }
            else {
                console.log("praise god")
                await updateDoc(userRef, {
                    isSubscribed: false
                });
            }
        });
    }
}

export async function upgradeAlert(uid) {
    const db = getFirestore();
    const userRef = await doc(db, 'users', uid);
    Swal.fire({
        title: 'Upgrade to TLDRMap+',
        text: 'Gain Access to new filters, weekly newsletter, and more features for only $0.99/month!',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Subscribe',
        cancelButtonText: 'Proceed with Free Version',
        customClass: 'dark:bg-zinc-700 dark:text-white'
    }).then(async (result) => {
        if (result.isConfirmed) {
            await createSub(uid);
        }
        else {
            await updateDoc(userRef, {
                isSubscribed: false
            });
        }
    });
}

async function fetchProducts() {
    const db = getFirestore();

    const productsRef = collection(db, "products");
    const productsQuery = query(productsRef, where("active", "==", true));
    const productsQuerySnap = await getDocs(productsQuery);
    let products = [];

    for (const doc of productsQuerySnap.docs) {
        const pricesRef = collection(db, "products", doc.id, "prices");
        const pricesQuerySnap = await getDocs(pricesRef);

        products.push({
            id: doc.id,
            ...doc.data(),
            prices: pricesQuerySnap.docs.map((price) => {
                return {
                    id: price.id,
                    ...price.data(),
                };
            }),
        });

        console.log("in loop", products[0]);
    }

    console.log("out of loop", products);
    return products;
}


export async function fetchSubscription(uid) {
    if(!uid) {
        return;
    }
    const db = getFirestore();
    const subsRef = collection(
        db,
        "users", uid, "subscriptions"
    );
    const subsQuery = query(
        subsRef,
        where("status", "in", ["trialing", "active", "past_due", "unpaid"])
    );

    let subscription = await getDocs(subsQuery)
        .then((sub) =>
            sub.docs.length > 0 ? sub.docs[0].data() : null);
    return subscription;
}   
