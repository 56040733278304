<template>
  <div v-for="(event, index) in events" :key="index">
    <UpdateTab :event="event" :urls="urls" :reactionCounts="reactionCounts" :timelineIndex ="index" :conflictName="conflictName"  @react="handleReaction"/>
  </div>
</template>

<script>
import  { emitArc } from "@/services/map/mapEvents.js"
import UpdateTab from './UpdateTab.vue';

export default {
  name: 'TimeLine',
  components: {
    UpdateTab,
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    conflictName: {
      type: String,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    selectedConflictKMLLayer: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      reactionCounts: {}, // Structure: { eventId: { reactionType: count } }
      urls: [
        { type: 'heart', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f49c/lottie.json' },
        { type: 'happy', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f600/lottie.json' },
        { type: 'pray', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f64f/lottie.json' },
        { type: 'surprised', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f631/lottie.json' },
        { type: 'cry', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f622/lottie.json' },
        { type: 'angry', animationUrl: 'https://fonts.gstatic.com/s/e/notoemoji/latest/1f621/lottie.json' }
      ],
      emojiData: []
    };
  },
  mounted() {
    // this.fetchEmojis()
  },
  methods: {
    // async fetchEmojis() {
    //   const promises = this.urls.map(async (item) => {
    //     try {
    //       const response = await fetch(item.URL);
    //       if (!response.ok) {
    //         throw new Error(`Network response was not ok for ${item.name}`);
    //       }
    //       const content = await response.json();
    //       return { name: item.name, content };
    //     } catch (error) {
    //       console.error(`There was a problem with the fetch operation for ${item.name}:`, error);
    //       return null;
    //     }
    //   });
    //   const results = await Promise.all(promises);
    //   this.emojiData = results.filter((result) => result !== null);
    // },
    getReactionCount(eventId, reactionType) {
      return (this.reactionCounts[eventId] && this.reactionCounts[eventId][reactionType]) || 0;
    },
    handleReaction({ eventId, reactionType }) {
      if (!this.reactionCounts[eventId]) {
        this.$set(this.reactionCounts, eventId, {});
      }
      if (!this.reactionCounts[eventId][reactionType]) {
        this.$set(this.reactionCounts[eventId], reactionType, 0);
      }
      this.reactionCounts[eventId][reactionType]++;
    },
    launchMapEvent(index) {
      const event = this.events[index];
      if (event.MapEvents) {
        event.MapEvents.forEach(eventDetail => {
          if (eventDetail.Coordinates) {
            const [startLat, startLng] = eventDetail.Coordinates[0];
            const [endLat, endLng] = eventDetail.Coordinates[1];
            emitArc(this.map, startLat, startLng, endLat, endLng);
          } else if (this.selectedConflictKMLLayer) {
            this.apply3DEffect(eventDetail.PopupEvent);
          }
        });
      }
    },
    apply3DEffect(layerName) {
      this.selectedConflictKMLLayer.eachLayer(layer => {
        if (layer.feature.properties.name === layerName) {
          const element = layer._icon || layer._path;
          if (element) {
            this.add3DEffect(element);
            setTimeout(() => this.remove3DEffect(element), 1000);
          }
        }
      });
    },
    add3DEffect(element) {
      element.style.transition = "transform 0.3s ease-out, box-shadow 0.3s ease-out";
      element.style.transform = "translateY(-2px) scale(1.1)";
      element.style.boxShadow = "0 4px 8px rgba(0, 0, 0, 0.5)";
      element.style.zIndex = 1000;
    },
    remove3DEffect(element) {
      element.style.transform = "";
      element.style.boxShadow = "";
      element.style.zIndex = "";
    },
  },
};
</script>