import AWS from 'aws-sdk';

export default function addReaction(index, conflictName, reaction) {
    const docClient = new AWS.DynamoDB.DocumentClient();
    const table_name = "TLDRMapConflicts";

    const params = {
        TableName: table_name,
        FilterExpression: "ConflictName = :conflictName",
        ExpressionAttributeValues: {
            ":conflictName": conflictName
        }
    };

    docClient.scan(params, async (err, data) => {
        if (err) {
            console.error("Error fetching data:", err);
        } else {
            if (data.Items.length === 0) {
                console.error("No conflicts found with the given name.");
                return;
            }

            const conflict = data.Items[0];

            const reversedIndex = conflict.Updates.length - 1 - index;

            // Access the update using the reversed index
            const update = conflict.Updates[reversedIndex];


            if (!update) {
                console.error("No update found at the given index.");
                return;
            }

            // Initialize the reaction array if it doesn't exist
            if (!update.reactionArray) {
                update.reactionArray = {
                    "heart": 0,
                    "happy": 0,
                    "pray": 0,
                    "surprised": 0,
                    "cry": 0,
                    "angry": 0,
                    "cap": 0,
                };
            }

            // Increment the specified reaction
            if (update.reactionArray[reaction] !== undefined) {
                update.reactionArray[reaction] += 1;
            } else {
                console.error("Invalid reaction type.");
                return;
            }

            // Prepare the update parameters
            const updateParams = {
                TableName: table_name,
                Key: {
                    ConflictName: conflictName
                },
                UpdateExpression: `set Updates[${reversedIndex}].reactionArray = :reactionArray`,
                ExpressionAttributeValues: {
                    ":reactionArray": update.reactionArray
                }
            };

            // Update the item in DynamoDB
            docClient.update(updateParams, (updateErr, updateData) => {
                if (updateErr) {
                    console.error("Error updating the reaction:", updateErr);
                } else {
                    console.log("Reaction updated successfully:", updateData);
                }
            });
        }
    });
}


export async function fetchReactions(conflictName, timelineIndex) {
    // const docClient = new AWS.DynamoDB.DocumentClient();
    // const table_name = "TLDRMapConflicts";

    // const params = {
    //     TableName: table_name,
    //     Key: {
    //         ConflictName: conflictName
    //     }
    // };

    // try {
    //     const data = await docClient.get(params).promise();
    //     const conflict = data.Item;

    //     const reversedIndex = conflict.Updates.length - 1 - timelineIndex;

    //     const reactionArray = conflict.Updates[reversedIndex]?.reactionArray;

    //     return reactionArray || {};
    // } catch (error) {
    //     console.error("Error fetching reactions:", error);
    //     return {};
    // }
    return {conflictName, timelineIndex};
}
