<template>
    <div class="modal">
        <div class="bg-blue-900 dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md w-full relative">
            
            <button @click="close" class="absolute top-0 right-0 mt-4 mr-4 cursor-pointer">
                <svg data-slot="icon" class="h-6 w-6 text-gray-500" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
                </svg>
            </button>
            
            <h1 class="modal-header">Login</h1>
            
            <div class="input-field">
                <div class="mb-6">
                    <input type="text" placeholder="Email" v-model="email" class="modal-input">
                    <input type="password" placeholder="Password" v-model="password" class="modal-input">
                </div>
                
                <div class="mb-6 flex flex-col gap-3">
                    <button @click="register" class="modal-submit-btn">Submit</button>
                    <button @click="signUpWithGoogle" class="modal-google-btn">Sign-In with Google</button>
                </div>
            </div>
            
            <div class="flex items-center justify-center">
                <p class="text-white dark:text-gray-400">Don't Have An Account?</p>
                <button @click="switchToSignup" class="ml-2 text-blue-500 hover:underline focus:outline-none">Signup</button>
            </div>
            
            <p v-if="errMsg">{{ errMsg }}</p>
        </div>
    </div>
</template>


<script>
export default {
  name: "LoginPage",
};
</script>

<script setup>
import { ref } from "vue";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useRouter } from "vue-router";


const props = defineProps({
  isOpen: {
    type: Boolean,
  },
});

const email = ref("");
const password = ref("");
const errMsg = ref("");
const router = useRouter();

const register = () => {
  signInWithEmailAndPassword(getAuth(), email.value, password.value)
    .then(() => {
      console.log("Successfully Registered!");
      router.push('/feed');
    })
    .catch((error) => {
      console.log(error.code);
      switch (error.code) {
        case "auth/invalid-email":
          errMsg.value = "Invalid email";
          alert(errMsg.value);
          break;
        case "auth/user-not-found":
          errMsg.value = "No account with that email was found";
          alert(errMsg.value);
          break;
        case "auth/wrong-password":
          errMsg.value = "Incorrect Password";
          alert(errMsg.value);
          break;
        default:
          errMsg.value = "Email or password is incorrect";
          alert(errMsg.value);
          break;
      }
    });
};

const signUpWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(getAuth(), provider)
    .then((result) => {
      console.log(result.user);
      router.push("/feed");
    })
    .catch((error) => {
      console.log(error);
    });
};

const close = () => {
  console.log(props.isOpen);
  emit('close');
};

const emit = defineEmits(['close', 'showSignupModal']);

const switchToSignup = () => {
  emit('showSignupModal');
};
</script>
