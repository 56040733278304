import L from "leaflet";
import { toRaw } from 'vue'
import useThemeStore from '@/stores/theme';

// import { handleZoomEvent } from "@/services/map/zoomFunctions.js"
function getGeojsonStyle(theme, conflictType) {
    const isDarkMode = theme === 'dark' ||
        (window.matchMedia('(prefers-color-scheme: dark)').matches && theme === 'auto')
    if (isDarkMode) {
        theme = "dark";
    }
    else {
        theme = "light"
    }
    const styles = {
        light: {
            "Invasion": "#488536",
            "Civil War": "#303567",
            "Frozen Conflict": "#9fc1cd",
            "Ethnic Conflict": "#5f919e",
            "Insurgency": "#649d8e",
            "Territorial Dispute": "#a48859",
            "default": "#303567"
        },
        dark: {
            "Invasion": "#53d92b",
            "Civil War": "#3b48cc",
            "Frozen Conflict": "#b5ecff",
            "Ethnic Conflict": "#58b8d1",
            "Insurgency": "#6ac4ad",
            "Territorial Dispute": "#e3b362",
            "default": "#53d92b"
        },
    };

    const fillColor = styles[theme][conflictType] || styles[theme]["default"];
    return { fillColor, weight: 2, opacity: 1, color: "#36454F", fillOpacity: 0.5 };
}

export function resetgeoJSONLayers(layer, selectedConflictType) {
    const themeStore = useThemeStore();
    const theme = themeStore.theme;
    const geojsonStyle = getGeojsonStyle(theme, selectedConflictType);
    layer.setStyle(geojsonStyle);
}


export function geoJSONSetup(parsedData, map, conflict, theme) {
    parsedData.features.forEach(feature => {
        feature.properties.Region = conflict.RegionOfConflict;
        feature.properties.TypeOfConflict = conflict.TypeOfConflict;
        feature.properties.ConflictName = conflict.ConflictName;
        if (conflict.Resources) feature.properties.Resources = conflict.Resources;
        if (conflict.Proxies) feature.properties.Proxies = conflict.Proxies;
        if (conflict.CombatTypes) feature.properties.CombatTypes = conflict.CombatTypes;
    });
    const geojsonStyle = getGeojsonStyle(theme, conflict.TypeOfConflict);
    const geojson = L.geoJSON(parsedData, {
        style: geojsonStyle,
    }).addTo(toRaw(map));

    geojson.eachLayer(layer => {
        layer.on({
            click: event => {
                if (map.getZoom() < 5) {
                    map.flyTo(event.latlng, 5);
                } else {
                    map.flyTo(event.latlng, map.getZoom());
                }
            }
        });
    });

    return geojson;
}
